import { Container, Table } from "react-bootstrap";

export default function InfoAzionistiENG() {
  return (
    <Container>
      <div>
        <div className="mt-5">
          <strong style={{ fontSize: "20px" }}>SHARE CAPITAL</strong>
          <div className="mt-3">
            <label>
              The share capital of Bertolotti S.p.A. is equal to Euro 1.568.172,68
              divided into 3.062.000 Ordinary Shares.
            </label>
          </div>
          <div className="mt-2">
            <label>
              The Ordinary Shares of Bertolotti S.p.A. are admitted to trading
              on Euronext Growth Milan - Segmento Professionale.
            </label>
          </div>
          <div className="mt-4">
            <Table>
              <tbody>
                <tr>
                  <td>Admission Price</td>
                  <td>€ 4,70</td>
                </tr>
                <tr>
                  <td>Market</td>
                  <td>Euronext Growth Milan - PRO</td>
                </tr>
                <tr>
                  <td>Number of ordinary shares</td>
                  <td>3.062.000</td>
                </tr>
                <tr>
                  <td>Stocks Ticker</td>
                  <td>TRAIN.MI</td>
                </tr>
                <tr>
                  <td>ISIN Code Shares</td>
                  <td>IT0005585127</td>
                </tr>
                {/* <tr>
                  <td>Number of Warrants</td>
                  <td>XXX</td>
                </tr>
                <tr>
                  <td>Ticker of Warrant</td>
                  <td>Ticker XXX</td>
                </tr>
                <tr>
                  <td>ISIN code of Warrant</td>
                  <td>ITxxx</td>
                </tr> */}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <strong style={{ fontSize: "20px" }}>SHAREHOLDERS</strong>
        <div className="mt-3">
          <label>
            The following table illustrates the composition of the shareholding
            structure, according to the results of the Shareholders' Register
            and on the basis of other information available to Bertolotti
            S.p.A.:
          </label>
        </div>
        <div className="mt-4">
          <Table>
            <thead>
              <tr style={{ backgroundColor: "#d1dde6" }}>
                <th className="text-center" style={{ background: "#d1dde6" }}>
                  <strong className="text-center">Shareholders</strong>
                </th>
                <th className="text-center" style={{ background: "#d1dde6" }}>
                  <strong className="text-center">No. Shares</strong>
                </th>
                <th className="text-center" style={{ background: "#d1dde6" }}>
                  <strong className="text-center">% of Share Capital</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">
                  <strong>Barneschi Holding</strong>
                </td>
                <td className="text-center">
                  <label>3.000.000</label>
                </td>
                <td className="text-center">
                  <label>97,98 %</label>
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  <strong>Market</strong>
                </td>
                <td className="text-center">
                  <label>62.000</label>
                </td>
                <td className="text-center">
                  <label>2,02 %</label>
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  <strong>Total</strong>
                </td>
                <td className="text-center">
                  <strong>3.062.000</strong>
                </td>
                <td className="text-center">
                  <strong>100,00%</strong>
                </td>
              </tr>
            </tbody>
          </Table>
          <div className="mt-1">
            <label>Last update: 21/03/2024</label>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <strong style={{ fontSize: "20px" }}>
          DISCLOSURE OBLIGATIONS FOR SIGNIFICANT SHAREHOLDERS
        </strong>
        <div className="mt-3">
          <label>
            Pursuant to the Euronext Growth Milan Issuers' Regulations approved
            and published by Borsa Italiana, as amended and supplemented from
            time to time (“
            <strong>Euronext Growth Milan Issuers' Regulations</strong>”),
            anyone holding a stake in the share capital of the issuer shall
            notify any “<strong>Substantial Change</strong>”, i.e., the reaching
            or exceeding of the thresholds equal to 5%, 10%, 15%, 20%, 25%, 30%,
            50%, 66.6% and 90% in the share capital of the issuer, as well as
            the reduction below the aforementioned thresholds, in order to allow
            the issuer to promptly communicate any Substantial Change without
            delay and disclose it to
          </label>
        </div>
        <div className="mt-3">
          <label>
            For the aforementioned purpose, the significant shareholder shall,
            promptly, and in any case within 4 trading days from the day on
            which the transaction giving rise to the obligation occurred
            (regardless of the execution date) or from the day on which this
            shareholder became aware of the events involving changes in the
            issuer's share capital, shall inform the issuer of:
          </label>
          <ol>
            <li>its own identity; </li>
            <li>the date on which the company has been informed;</li>
            <li>
              the date on which he has acquired or sold the share capital's
              percentage which determined the “Substantial Change” or the date
              on which its shareholding increased or decreased reaching or
              exceeding the thresholds indicated in the Euronext Growth Milan
              Issuers' Regulations;
            </li>
            <li>
              the price, amount and category of the Bertolotti S.p.A. financial
              instruments involved;
            </li>
            <li>the nature and consideration of the transaction;</li>
            <li>the amount of the shareholding.</li>
          </ol>
        </div>
        <div className="mt-3">
          <label>
            The communication can be realized using the appropriate form that
            can be downloaded at the following link, by e-mail at the following
            e-mail address:&nbsp;
            <a href="mailto:investor-relations@bertolottispa.it">
                investor-relations@bertolottispa.it
            </a>
            ,&nbsp;
            <a href="mailto:l.barneschi@bertolottispa.it">
              l.barneschi@bertolottispa.it
            </a>
            .
          </label>
        </div>
        <div className="mt-3">
          <strong style={{ fontSize: "16px" }}>
            Notification of substantial change
          </strong>
          <div className="mt-2">
            <button
              onClick={() =>
                window.open(
                  "https://drive.google.com/u/0/uc?id=1VJ1v_gPcw2EwL20yofadVoAujo4X0nyQ&export=download",
                  "_blank"
                )
              }
              className="btn btn-danger"
            >
              Download
            </button>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <strong style={{ fontSize: "20px" }}>MAIN ADVISORS</strong>
        <div className="mt-4">
          <Table>
            <thead>
              <tr>
                <th className="text-center">
                  <strong>COMPANY</strong>
                </th>
                <th className="text-center">
                  <strong>ROLE</strong>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="text-center">
                  <label>Illimity Bank S.p.A.</label>
                </td>
                <td className="text-center">
                  <label>Euronext Growth Advisor e Global Coordinator</label>
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  <label>Studio Gianni & Origoni</label>
                </td>
                <td className="text-center">
                  <label>Legal Advisor</label>
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  <label>Rewind S.r.l.</label>
                </td>
                <td className="text-center">
                  <label>Financial Advisor</label>
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  <label>BDO Italia S.p.A.</label>
                </td>
                <td className="text-center">
                  <label>Auditing Firm</label>
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  <label>First Tax & Legal</label>
                </td>
                <td className="text-center">
                  <label>Tax Advisor</label>
                </td>
              </tr>
              <tr>
                <td className="text-center">
                  <label>Twister communications group S.r.l.</label>
                </td>
                <td className="text-center">
                  <label>Communication Advisor</label>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </Container>
  );
}

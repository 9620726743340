import React from "react";
import { ComunicatiStampaProps } from "../utility/interface";
import { MdOutlineDownloading } from "react-icons/md";
import { useLanguageContext } from "../Context/LanguageContext";

export default function EventiComunicati({ data }: ComunicatiStampaProps) {
    const { language } = useLanguageContext()

    const handleClick = (link: string) => {
        if(link === '' || !link) return
        window.open(link, '_blank')
    }

    const stampaData = data.map((p, index) => {

        const stampaEventi = p.list.map((d, index) => {
            return (
                <>
                    <div onClick={handleClick.bind(this, language === 'it' ? d.link : d.linkEN)} id="evento" className={`w-100 mt-2`}>
                        <div className="d-flex flex-row">
                            <div className={`w-100`}>
                                <div className="ms-3 pt-3">
                                    <label style={{ color: '#C00322' }}>{d.data}</label>
                                </div>
                                <div className="ms-3 pt-3 pb-3">
                                    <label>{language === 'it' ? d.title : d.titleEN ? d.titleEN : d.title}</label>
                                </div>
                            </div>
                            {
                                language === 'it' && d.link !== '' && d.link && (
                                    <div className="w-25 d-flex align-items-center justify-content-end me-3">
                                        <MdOutlineDownloading size={35} style={{ color: '#C00322' }} />
                                    </div>
                                )
                            }
                            {
                                language === 'en' && d.linkEN !== '' && d.linkEN && (
                                    <div className="w-25 d-flex align-items-center justify-content-end me-3">
                                        <MdOutlineDownloading size={35} style={{ color: '#C00322' }} />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </>
            )
        })

        return (
            <>
                <div className="mt-4">
                    <div style={{ borderBottom: '1px solid black' }}>
                        <h4><label style={{ color: '#C00322' }}>{p.anno}</label></h4>
                    </div> 
                    <div>
                        {stampaEventi}
                    </div>
                </div>
            </>
        )
    })

    return (
        <>
            <div className="mt-5">
                {stampaData}
            </div>
        </>
    )
}
import { useLanguageContext } from "../../../Context/LanguageContext"

export default function MilestonesItem({ title, titleEN, list }) {

    const { language } = useLanguageContext()

    return (
        <div className="col-md-2" style={{ borderLeft: '2px solid #C01725', borderLeftStyle: 'dotted' }}>
            <div>
                {
                    list.map((p, index) => (
                        <div className="mt-3">
                            <strong style={{ fontSize: '16px' }}>{language === 'en' ? p.titleEN : p.title}</strong>
                            <div>
                                <label style={{ fontSize: '14px' }}>
                                    {language === 'en' ? p.descEN : p.desc}
                                </label>
                            </div>
                        </div>
                    ))
                }
            </div>
        </div>
    )
}
import cookieCutter from 'cookie-cutter'

import { Toast, ToastContainer, Button } from 'react-bootstrap';  
import { useLanguageContext } from '../../Context/LanguageContext';

export default function CookieComponent() {

    const { cookieConsent, setCookieConsent } = useLanguageContext();

    function handleAccept() {
        cookieCutter.set('cookie-consent-bertolotti', 'true', { expires: 365 });
        setCookieConsent('true')
    }

    function handleReject() {
        cookieCutter.set('cookie-consent-bertolotti', 'false', { expires: 365 });
        setCookieConsent('false')
    }

    if(cookieConsent !== undefined && cookieConsent !== null) return null

    return (
        <ToastContainer
            position='bottom-start'
            style={{ zIndex: 1 }}
            className='position-fixed p-4'
        >
            <Toast style={{ width: '440px' }}>
                <Toast.Body className='bg-white p-4'>
                    <div>
                        <strong style={{ fontSize: '22px' }} className='text-black'>Diamo valore alla tua privacy</strong>
                    </div>
                    <div className='mt-3'>
                        <label>
                            Questo sito utilizza i cookie per offrirti la migliore esperienza di navigazione possibile. 
                            Per accettare l'uso dei cookie, clicca su "Accetta". 
                            Per maggiori informazioni sui cookie che utilizziamo e su come personalizzare i parametri relativi al loro uso, consulta la nostra <a target='_blank' href="/documents/cookies.pdf">Privacy Policy</a>.
                        </label>
                    </div>
                    <div className="mt-4">
                        <div className='flex'>
                            <Button onClick={handleReject} variant='outline-primary'>
                                Rifiutare tutto
                            </Button>
                            <Button onClick={handleAccept} className='ms-3' variant='primary'>
                                Accettare tutto
                            </Button>
                        </div>
                    </div>
                </Toast.Body>
            </Toast>
        </ToastContainer>
    )
}
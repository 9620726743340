const ProdottoCard = ({ prodotto, isExpanded, onToggle, lang }) => {
  return (
    <div className="card card-prod bg-light position-relative">
      <img src={prodotto.img} className="card-img-top" alt={prodotto.nome} />
      <div className="icon-overlay position-absolute">
        <img src={prodotto.icon} className="img-fluid" style={{width: '30px'}} alt=""/>
      </div>
      <div className="card-body">
        <div className="card-title-container">
          <h5 className="card-title text-center">
            {lang === 'en' ? (prodotto.name) : (prodotto.nome)}
          </h5>
        </div>
        {isExpanded ? (
          <>
            <p className="card-text mt-3">{lang === 'en' ? (prodotto.description) : (prodotto.descrizione)}</p>
            <div className="text-center">
              <button className="btn btn-secondary" onClick={() => onToggle(prodotto.id)}>Read Less</button>
            </div>
          </>
        ) : (
          <div className="text-center mt-3">
            <button className="btn btn-secondary" onClick={() => onToggle(prodotto.id)}>Read More</button>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProdottoCard;

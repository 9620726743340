import MilestonesItem from "./MilestonesComponents/MilestonesItem"

export default function Milestones() {

    const data = [
        {
            data: "1972",
            list: [
                {
                    titleEN: 'Bertolotti S.p.a. is born',
                    title: 'Nasce Bertolotti S.p.a.',
                    descEN: 'Luigi Bertolotti founded the company, initially operating in the bulk handling sector (conveyor belts, hoppers).',
                    desc: "Luigi Bertolotti fonda l'azienda, operando inizialmente nel settore della movimentazione di prodotti sfusi (nastri trasportatori, tramogge)."
                }
            ]
        },
        {
            data: "1976",
            list: [
                {
                    titleEN: 'Steel Industry',
                    title: 'Industria siderurgica',
                    descEN: 'First steel contract for the handling of coils.',
                    desc: 'Primo contratto siderurgico per la movimentazione di coils.'
                }
            ]
        },
        {
            data: "1998",
            list: [
                {
                    titleEN: 'Acquisition of Ital-engineering S.r.l.',
                    title: 'Acquisizione di Ital-engineering S.r.l.',
                    descEN: 'The company is acquired by the current ownership, later in 2020 the company name is changed to Bertolotti Group S.r.l.',
                    desc: 'L\'azienda viene acquisita dall\'attuale proprietà, poi nel 2020 la ragione sociale viene cambiata in Bertolotti Group S.r.l.'
                }
            ]
        },
        {
            data: "2002",
            list: [
                {
                    titleEN: 'Rail Industry',
                    title: 'Industria ferroviaria',
                    descEN: 'First railway contract for sand supply system.',
                    desc: 'Primo contratto ferroviario per il sistema di approvvigionamento di sabbia.'
                }
            ]
        },
        {
            data: "2009",
            list: [
                {
                    titleEN: 'Aeronautical',
                    title: 'Aeronautica',
                    descEN: 'First aeronautical contract for the handling and manufacturing aircraft.',
                    desc: 'Primo contratto aeronautico per la movimentazione e la produzione di velivoli.'
                }
            ]
        },
        {
            data: "2011",
            list: [
                {
                    titleEN: 'First nuclear warehouse management system',
                    title: 'Primo sistema di gestione del magazzino rifiuti debolmente radioattivi.',
                    descEN: '',
                    desc: ''
                }
            ]
        }
    ]

    const data2 = [
        {
            data: "2017",
            list: [
                {
                    titleEN: 'Bertolotti Rail S.r.l. is born',
                    title: 'Nasce Bertolotti rail S.r.l.',
                    descEN: 'The strategic choice to create a sector with standard but "configurable" to the customer\'s needs, led in 2017 to the birth of Bertolotti Rail S.r.l., 100% controlled by Bertolotti S.p.A., which is entrusted with the railway sector.',
                    desc: 'La scelta strategica di creare un settore standard ma "configurabile" sulle esigenze del cliente ha portato nel 2017 alla nascita di Bertolotti Rail S.r.l., controllata al 100% da Bertolotti S.p.A., a cui è affidato il settore ferroviario.'
                },
                {
                    titleEN: '"Concordato" of Bertolotti S.p.a',
                    title: '"Concordato" di Bertolotti S.p.a',
                    descEN: 'Due to a mix of factors Bertolotti suffers a period of financial stress and chooses the path of the "concordato"',
                    desc: 'Per un insieme di fattori Bertolotti subisce un periodo di stress finanziario e sceglie la strada del "concordato".'
                },
                {
                    titleEN: 'First automated warehouse',
                    title: 'Il primo magazzino automatizzato',
                    descEN: 'Bertolotti tests the first automated warehouse for coils.',
                    desc: 'Bertolotti sperimenta il primo magazzino automatizzato per rotoli.'
                }
            ]
        },
        {
            data: "2019",
            list: [
                {
                    titleEN: 'Homologation of "concordato" Bertolotti S.p.a.',
                    title: 'Omologazione del "concordato" Bertolotti S.p.a.',
                    descEN: '',
                    desc: ''
                },
                {
                    titleEN: 'First AGV System',
                    title: 'Primo sistema AGV',
                    descEN: "Bertolotti tests a revolutionary project for the use of AGV in repacement of diesel and manual forklift.",
                    desc: "Bertolotti sperimenta un progetto rivoluzionario per l'utilizzo di AGV in sostituzione di carrelli elevatori manuali e diesel."
                },
                {
                    titleEN: 'Corporate efficiency and execution of the "concordato" in adherence to the plan',
                    title: 'Efficienza aziendale ed esecuzione del "concordato" in aderenza al piano',
                    descEN: 'Implementation of «Corporate Restructuring e Turnaround Management Strategies».',
                    desc: 'Attuazione di "Strategie di Ristrutturazione Aziendale e Turnaround Management".'
                },
                {
                    titleEN: 'First Railway Integrated Deposit',
                    title: 'Primo Deposito integrato Ferroviario',
                    descEN: "Bertolotti wins the contract for the new railway deposit in Italy.",
                    desc: "Bertolotti si aggiudica l'appalto per  un nuovo deposito ferroviario in Italia."
                }
            ]
        },
        {
            data: "2020",
            list: [
                {
                    titleEN: 'New integrated management system',
                    title: 'Nuovo sistema di gestione integrato',
                    descEN: 'CRM (Sales Force), Order Management (Blean), ERP (SAP) - Cash flow (Doc Finace) - BI',
                    desc: 'CRM (Sales Force), Gestione Ordini (Blean), ERP (SAP) - Flusso di cassa (Doc Finace) - BI'
                },
                {
                    titleEN: 'First deposit 4.0 SNFC',
                    title: 'Primo deposito 4.0 SNFC',
                    descEN: "Bertolotti wins the contract for the management of logistic thru AGV: it will be the most modern in Europe.",
                    desc: "Bertolotti si aggiudica l'appalto per la gestione della logistica tramite AGV: sarà il più moderno d'Europa."
                },
                {
                    titleEN: 'Second automated warehouse',
                    title: 'Secondo magazzino automatizzato',
                    descEN: "Bertolotti tests one of the biggest automated warehouses in Europe",
                    desc: "Bertolotti collauda uno dei più grandi magazzini automatizzati d'Europa."
                }
            ]
        },
        {
            data: "2022",
            list: [
                {
                    titleEN: 'Acquisition of Pontassieve site',
                    title: 'Acquisizione del sito di Pontassieve',
                    descEN: "In August 2022, Bertolotti Rail S.r.l. acquired an industrial railway pole in the Municipality of Pontassieve, sold by Ferrovie dello Stato, through a pool loan granted by several mutual banks for approximately Euro 3 million.",
                    desc: "Nell'agosto 2022 Bertolotti Rail S.r.l. ha acquisito un polo ferroviario industriale nel Comune di Pontassieve, ceduto da Ferrovie dello Stato, attraverso un finanziamento in pool concesso da diverse banche mutualistiche per circa 3 milioni di euro."
                },
                {
                    titleEN: 'Second railway integrated deposit',
                    title: 'Secondo deposito ferroviario integrato',
                    descEN: "Bertolotti wins the contract for the new railway deposit in Italy",
                    desc: "Bertolotti si aggiudica l'appalto di un nuovo deposito ferroviario in Italia."
                }
            ]
        },
        {
            data: "2023",
            list: [
                {
                    titleEN: 'First order on the German market',
                    title: 'Primo ordine sul mercato tedesco',
                    descEN: "Bertolotti wins the first oder for the public German Railways.",
                    desc: "Bertolotti si aggiudica il primo ordine sul mercato tedesco."
                },
                {
                    titleEN: 'End of "concordato preventivo" and issue of bond loan',
                    title: 'Fine del "concordato preventivo" e emissione di obbligazioni',
                    descEN: "In March, Bertolotti S.p.A. closed in advance, with consequential return to bonis, the «Concordato preventivo» omologated in 2019 with the issue of a bond loan of Euro 3 million on Wiener Borse AG (Vienna Stock Exchange).",
                    desc: "A marzo Bertolotti S.p.A. ha chiuso anticipatamente, con conseguente ritorno in bonis, il \"Concordato preventivo\" omologato nel 2019 con l'emissione di un prestito obbligazionario di 3 milioni di euro presso la Wiener Borse AG (Borsa di Vienna)."
                },
                {
                    titleEN: 'First contract in the USA',
                    title: 'Primo contratto negli USA',
                    descEN: "Bertolotti acquire the first contract in the USA.",
                    desc: "Bertolotti acquisisce il primo contratto negli USA."
                },
                {
                    titleEN: "Bertolotti AI Works S.r.l. is born",
                    title: "Nasce Bertolloti AI Works S.r.l.",
                    descEN: "Bertolotti AI Works was born in July 2023 with the aim of acquiring, through rental of a business unit, Autognity S.r.l. for carrying out the manufacturing activity of industrial robots for multiple uses.",
                    desc: "Bertolotti AI Works nasce nel luglio 2023 con l'obiettivo di acquisire, tramite l'affitto di un ramo d'azienda una società per lo svolgimento dell'attività di produzione di robot industriali per molteplici usi."
                }
            ]
        }
    ]

    return (
        <>
            <div className="d-none d-lg-block">
                <div>
                    <strong style={{ fontSize: '48px' }}>Historical Milestones</strong>
                </div>
                <div className="mt-4">
                    <div className="row">
                        <div className="col-md-2">
                            <div>
                                <strong style={{ fontSize: '38px', color: '#C01725' }}>1972</strong>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <strong style={{ fontSize: '38px', color: '#C01725' }}>1976</strong>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <strong style={{ fontSize: '38px', color: '#C01725' }}>1998</strong>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <strong style={{ fontSize: '38px', color: '#C01725' }}>2002</strong>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <strong style={{ fontSize: '38px', color: '#C01725' }}>2009</strong>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <strong style={{ fontSize: '38px', color: '#C01725' }}>2011</strong>
                            </div>
                        </div>
                    </div>
                    <div style={{ background: '#C01725', height: '4px', width: '100%' }} />
                    <div className="row mt-4 ms-1">
                        {
                            data.map(p => (
                                <MilestonesItem {...p} />
                            ))
                        }
                    </div>
                </div>
                <div className="mt-5">
                    <div className="row">
                        <div className="col-md-2">
                            <div>
                                <strong style={{ fontSize: '38px', color: '#C01725' }}>2017</strong>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <strong style={{ fontSize: '38px', color: '#C01725' }}>2019</strong>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <strong style={{ fontSize: '38px', color: '#C01725' }}>2020</strong>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <strong style={{ fontSize: '38px', color: '#C01725' }}>2022</strong>
                            </div>
                        </div>
                        <div className="col-md-2">
                            <div>
                                <strong style={{ fontSize: '38px', color: '#C01725' }}>2023</strong>
                            </div>
                        </div>
                    </div>
                    <div style={{ background: '#C01725', height: '4px', width: '100%' }} />
                    <div className="row mt-4 ms-1">
                        {
                            data2.map(p => (
                                <MilestonesItem {...p} />
                            ))
                        }
                    </div>
                </div>
            </div>
            <div className="d-block d-lg-none">
                {
                    data.map(p => (
                        <div className="mt-3">
                            <div className="py-2 mb-3" style={{ borderBottom: '2px solid #C01725' }}>
                                <strong style={{ fontSize: '24px', color: '#C01725' }}>{p.data}</strong>
                            </div>
                            <div className="row">
                                {
                                    p.list.map(l => (
                                        <div className="col-md-3">
                                            <strong>{l.title}</strong>
                                            <p>{l.desc}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
                {
                    data2.map(p => (
                        <div className="mt-3">
                            <div className="py-2 mb-3" style={{ borderBottom: '2px solid #C01725' }}>
                                <strong style={{ fontSize: '24px', color: '#C01725' }}>{p.data}</strong>
                            </div>
                            <div className="row">
                                {
                                    p.list.map(l => (
                                        <div className="col-md-3">
                                            <strong>{l.title}</strong>
                                            <p>{l.desc}</p>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    ))
                }
            </div>
        </>
    )
}
import { createContext, useEffect, useState, useContext } from "react";

const DocsContext = createContext({
    docs: {}
});

export default function DocsContextProvider({ children }) {
    const [docs, setDocs] = useState()
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        async function getDocsFromS3() {
            setIsLoaded(false)
            const response = await fetch('https://bertolotti-assets.s3.eu-south-1.amazonaws.com/investor-relations/investor-relations.json')
            const data = await response.json()
            setDocs(data)
            setIsLoaded(true)
        }

        getDocsFromS3()
    }, [])

    const value = {
        docs
    }

    if (!isLoaded) return (
        <div style={{ width: '100vw', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <div className="loading-dots">
                <div className="dot"></div>
                <div className="dot"></div>
                <div className="dot"></div>
            </div>
        </div>
    )

    return (
        <DocsContext.Provider value={value}>
            {children}
        </DocsContext.Provider>
    )
}

export const useDocsContext = () => useContext(DocsContext)
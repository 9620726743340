import { Container, Table } from "react-bootstrap";

export default function InfoAzionistiITA() {
    return (
        <Container>
            <div>
                <div className="mt-5">
                    <strong style={{ fontSize: "20px" }}>
                        CAPITALE SOCIALE
                    </strong>
                    <div className="mt-3">
                        <label>
                            Il capitale sociale di Bertolotti S.p.A. è pari ad
                            Euro 1.568.172,68 suddiviso in n. 3.062.000 Azioni Ordinarie.
                        </label>
                    </div>
                    <div className="mt-2">
                        <label>
                            Le Azioni Ordinarie di Bertolotti S.p.A. sono
                            ammesse alle negoziazioni su Euronext Growth Milan -
                            Segmento Professionale.
                        </label>
                    </div>
                    <div className="mt-4">
                        <Table>
                            <tbody>
                                <tr>
                                    <td>Prezzo di Ammissione</td>
                                    <td>€ 4,70</td>
                                </tr>
                                <tr>
                                    <td>Mercato</td>
                                    <td>Euronext Growth Milan - PRO</td>
                                </tr>
                                <tr>
                                    <td>Numero Azioni ordinarie</td>
                                    <td>3.062.000</td>
                                </tr>
                                <tr>
                                    <td>Ticker Azioni</td>
                                    <td>TRAIN.MI</td>
                                </tr>
                                <tr>
                                    <td>Codice ISIN Azioni</td>
                                    <td>IT0005585127</td>
                                </tr>
                                {/* <tr>
                                    <td>Numero Warrant</td>
                                    <td>XXX</td>
                                </tr>
                                <tr>
                                    <td>Ticker Warrant</td>
                                    <td>Ticker XXX</td>
                                </tr>
                                <tr>
                                    <td>Codice ISIN Warrant</td>
                                    <td>ITxxx</td>
                                </tr> */}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <strong style={{ fontSize: "20px" }}>AZIONARIATO</strong>
                <div className="mt-3">
                    <label>
                        La seguente tabella illustra la composizione della
                        compagine sociale, secondo le risultanze del libro soci
                        nonché sulla base delle altre informazioni a
                        disposizione di Bertolotti S.p.A.:
                    </label>
                </div>
                <div className="mt-4">
                    <Table>
                        <thead>
                            <tr style={{ backgroundColor: "#d1dde6" }}>
                                <th
                                    className="text-center"
                                    style={{ background: "#d1dde6" }}
                                >
                                    <strong className="text-center">
                                        Azionisti
                                    </strong>
                                </th>
                                <th
                                    className="text-center"
                                    style={{ background: "#d1dde6" }}
                                >
                                    <strong className="text-center">
                                        N. Azioni
                                    </strong>
                                </th>
                                <th
                                    className="text-center"
                                    style={{ background: "#d1dde6" }}
                                >
                                    <strong className="text-center">
                                        % sul capitale sociale
                                    </strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-center">
                                    <strong>Barneschi Holding</strong>
                                </td>
                                <td className="text-center">
                                    <label>3.000.000</label>
                                </td>
                                <td className="text-center">
                                    <label>97,98 %</label>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center">
                                    <strong>Mercato</strong>
                                </td>
                                <td className="text-center">
                                    <label>62.000</label>
                                </td>
                                <td className="text-center">
                                    <label>2,02 %</label>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center">
                                    <strong>Totale</strong>
                                </td>
                                <td className="text-center">
                                    <strong>3.062.000</strong>
                                </td>
                                <td className="text-center">
                                    <strong>100,00%</strong>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <div className="mt-1">
                        <label>Data ultimo aggiornamento: 21/03/2024</label>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <strong style={{ fontSize: "20px" }}>
                    OBBLIGHI INFORMATIVI DEGLI AZIONISTI SIGNIFICATIVI
                </strong>
                <div className="mt-3">
                    <label>
                        Ai sensi del Regolamento Emittenti Euronext Growth Milan
                        approvato e pubblicato da Borsa Italiana e successive
                        modifiche e integrazioni (“Regolamento Emittenti
                        Euronext Growth Milan”) chiunque detenga almeno il 5% di
                        una categoria di azioni di Bertolotti S.p.A. è un
                        “Azionista Significativo”.
                    </label>
                </div>
                <div className="mt-3">
                    <label>
                        Il raggiungimento o il superamento delle soglie del 5%,
                        10%, 15%, 20%, 25%, 30%, 50%, 66,6%, e 90% del capitale
                        sociale, nonché le riduzioni al di sotto di tali soglie,
                        costituiscono, ai sensi del Regolamento Emittenti
                        Euronext Growth Milan, un “Cambiamento Sostanziale” che
                        deve essere comunicato dagli Azionisti Significativi
                        (ovvero dagli azionisti che in virtù dell'operazione
                        abbiano assunto tale status) al'organo amministrativo
                        di Bertolotti S.p.A.
                    </label>
                </div>
                <div className="mt-3">
                    <label>
                        A tal fine, l'Azionista Significativo (ovvero
                        l'azionista che in virtù dell'operazione abbia assunto
                        tale status) deve comunicare a Bertolotti S.p.A. il
                        Cambiamento Sostanziale tempestivamente e comunque entro
                        4 giorni di negoziazione decorrenti dal giorno in cui è
                        venuto a conoscenza dell'operazione idonea a determinare
                        il sorgere dell'obbligo, indipendentemente dalla data di
                        esecuzione, ovvero da quello in cui il soggetto tenuto
                        all'obbligo è venuto a conoscenza degli eventi che
                        comportano modifiche del capitale sociale indicando:
                    </label>
                    <ol>
                        <li>la propria identità;</li>
                        <li>
                            la data in cui è avvenuto il Cambiamento Sostanziale
                            delle partecipazioni;
                        </li>
                        <li>
                            la natura e l'entità della partecipazione
                            dell'Azionista Significativo nell'operazione; nelle
                            ipotesi di emissione di azioni a voto plurimo, il
                            numero di diritti di voto e il numero di azioni
                            ordinarie detenute.
                        </li>
                    </ol>
                </div>
                <div className="mt-3">
                    <label>
                        La comunicazione deve essere effettuata utilizzando il
                        modulo allegato, da trasmettersi sia via e-mail sia via
                        PEC rispettivamente ai seguenti indirizzi:&nbsp;
                        <a href="mailto:investor-relations@bertolottispa.it">
                            investor-relations@bertolottispa.it
                        </a>
                        ,&nbsp;
                        <a href="mailto:l.barneschi@bertolottispa.it">
                            l.barneschi@bertolottispa.it
                        </a>
                        .
                    </label>
                </div>
                <div className="mt-3">
                    <strong style={{ fontSize: '16px' }}>Modulo comunicazione cambiamento sostanziale</strong>
                    <div className="mt-2">
                        <button onClick={() => window.open('https://drive.google.com/u/0/uc?id=1FtN6c6WFs_FkozaiThyboBVq6HQ1L9YX&export=download', '_blank')} className="btn btn-danger">
                            Download
                        </button>
                    </div>
                </div>
            </div>
            <div className="mt-5">
                <strong style={{ fontSize: "20px" }}>CONSULENTI</strong>
                <div className="mt-4">
                    <Table>
                        <thead>
                            <tr>
                                <th className="text-center">
                                    <strong>SOCIETA'</strong>
                                </th>
                                <th className="text-center">
                                    <strong>RUOLO</strong>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className="text-center">
                                    <label>Illimity Bank S.p.A.</label>
                                </td>
                                <td className="text-center">
                                    <label>
                                        Euronext Growth Advisor e Global
                                        Coordinator
                                    </label>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center">
                                    <label>Studio Gianni & Origoni</label>
                                </td>
                                <td className="text-center">
                                    <label>Advisor Legale</label>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center">
                                    <label>Rewind S.r.l.</label>
                                </td>
                                <td className="text-center">
                                    <label>Advisor Finanziario</label>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center">
                                    <label>BDO Italia S.p.A.</label>
                                </td>
                                <td className="text-center">
                                    <label>Società di Revisione</label>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center">
                                    <label>First Tax & Legal</label>
                                </td>
                                <td className="text-center">
                                    <label>Consulente Fiscale</label>
                                </td>
                            </tr>
                            <tr>
                                <td className="text-center">
                                    <label>
                                        Twister communications group S.r.l.
                                    </label>
                                </td>
                                <td className="text-center">
                                    <label>Advisor di Comunicazione</label>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </div>
            </div>
        </Container>
    );
}

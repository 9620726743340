import Slider from "react-slick";
import HeaderSliderItem from "./HeaderSliderComponents/HeaderSliderItem";

export default function HeaderSlider() {

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnHover: true
    };

    const data = [
        {
            imgSrc: '/assets/img/header2-min.png',
            title: 'Bertolotti Industries',
            desc: "Specializzata nella creazione di sistemi logistici per i settori dell'acciaio e dell'alluminio e aerospaziale",
            titleEN: 'Bertolotti Industries',
            descEN: "Specialized in the creation of logistic systems for the steel and aerospace sectors"
        },
        {
            imgSrc: '/assets/img/header-min.png',
            title: 'Bertolotti Rail',
            desc: "Specializzata nella costruzione di sistemi per la manutenzione del materiale rotabile ferroviario (metropolitane e tram)",
            titleEN: 'Bertolotti Rail',
            descEN: "Specialized in the constructin of systems for the maintenance of railway rolling stock (subway and trams)"
        },
        {
            imgSrc: '/assets/img/header1-min.png',
            title: 'Bertolotti AI Works',
            desc: "Specializzata nello sviluppo di software e nella creazione di sistemi robotici",
            titleEN: 'Bertolotti AI Works',
            descEN: "Specialized in software development and the creation of robotic systems"
        }
    ]

    return (
        <div style={{ maxWidth: '100vw' }}>
            <Slider {...settings}>
                <HeaderSliderItem {...data[0]} />
                <HeaderSliderItem {...data[1]} />
                <HeaderSliderItem {...data[2]} />
            </Slider>
        </div>
    )
}
import React from "react";
import { FiMail } from 'react-icons/fi'

export default function ContattiInvestor() {

    return (
        <>
            <div className="mt-1">
                <label className="d-flex align-items-center" style={{ fontSize: '24px' }}>Contattaci al seguente indirizzo mail</label>
            </div>
            <div className="mt-1">
                <label className="d-flex align-items-center"><FiMail size={'15'} /> <a href="mailto:investor-relations@bertolottispa.it" className="ms-2">investor-relations@bertolottispa.it</a></label>
            </div>
            <div className="mt-1">
                <label className="d-flex align-items-center"><FiMail size={'15'} /> <a href="mailto:l.barneschi@bertolottispa.it" className="ms-2">l.barneschi@bertolottispa.it</a></label>
            </div>
        </>
    )
}
import { useEffect } from "react";
import { createContext, useContext, useState } from "react";

import cookieCutter from 'cookie-cutter'

const LanguageContext = createContext();

export default function LanguageContextProvider({ children }) {
    const [language, setLanguage] = useState('it');

    const [cookieConsent, setCookieConsent] = useState(cookieCutter.get('cookie-consent-bertolotti'));

    useEffect(() => {
        if(window.location.pathname === '/en') {
            setLanguage('en')
            return
        }

        setLanguage('it')
    }, [])

    const value = {
        language,
        setLanguage,
        cookieConsent,
        setCookieConsent
    }

    return (
        <LanguageContext.Provider value={value}>
            {children}
        </LanguageContext.Provider>
    )
}

export const useLanguageContext = () => useContext(LanguageContext)
// import './../InvestorRelator.css';
import NavbarComponent from './../components/NavbarComponent';
import Footer from './../components/Footer';
import { Row, Col, Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import ListFileTitle from './../components/ListFileTitle';
import Faq from './../components/FaqComponents.tsx';
import LateralMenu from './../components/LateralMenu.tsx';
import OrganiSociali from './../view/OrganiSociali.jsx';
import EventiComunicati from './../components/EventiComunicati.tsx';
import Helmet from 'react-helmet'
import ContattiInvestor from './../components/ContattiInvestor';
import IPO from '../view/IPO.jsx';
import InfoAzionisti from '../view/InfoAzionisti.jsx';

import ReactGA from "react-ga4";
import { useLanguageContext } from '../Context/LanguageContext.jsx';
import { useDocsContext } from '../Context/DocsContext.jsx';

function InvestorRelator() {

  const { cookieConsent } = useLanguageContext();
  const { docs } = useDocsContext() 

  const [dataLateralMenu, setDataLateralMenu] = useState([])
  const [tab, setTab] = useState('DOCUMENTI SOCIETARI')
  const [childrenTab, setChildrenTab] = useState()


  useEffect(() => {
    setDataLateralMenu(
      [
        {
          title: '<strong>Corporate Governance</strong>',
          list: [
            { 
                "nome": 'ORGANI SOCIALI' 
            }, 
            { 
              "nome": 'DOCUMENTI SOCIETARI'
            },
            {
              "nome": "INTERNAL DEALING"
            },
            {
              "nome": "ASSEMBLEE DEGLI AZIONISTI"
            },
          ]
        },
        {
          title: '<strong>Investor Relations</strong>',
          list: [
            {
              "nome": "CALENDARIO FINANZIARIO"
            },
            {
              "nome": "BILANCI E RELAZIONI"
            },
            // {
            //   "nome": "COMUNICATI STAMPA PRICE SENSITIVE"
            // },
            {
              "nome": "COMUNICATI STAMPA PRICE SENSITIVE"
            },
            {
              "nome": "COMUNICATI STAMPA"
            },
            // {
            //   "nome": "DEBT CAPITAL MARKETS"
            // },
            {
              "nome": "INFO PER GLI AZIONISTI"
            },
            {
              "nome": "PRESENTAZIONI AZIENDALI"
            },
            {
              "nome": "COVERAGE"
            },
            {
              "nome": "CONTATTI INVESTOR RELATOR"
            },
            {
              "nome": "IPO"
            },
          ]
        }
      ]
    )
  }, [])

  useEffect(() => {
    const getTab = () => {
      switch(tab) {
        case 'ORGANI SOCIALI': 
          setChildrenTab(
            <>
              <OrganiSociali societa={docs['Società di revisione']} consiglio={docs.ConsiglioAmministrazione} collegio={docs.CollegioSindacale} />
            </>
          )
          break;
        case 'DOCUMENTI SOCIETARI':
          setChildrenTab(
            <>
              <div className='pt-3 pb-5'>
                <ListFileTitle data={docs.Documenti} />
              </div>
            </>
          )
          break;
        case 'INTERNAL DEALING':
          setChildrenTab(
            <>
              <EventiComunicati data={docs.INTERNAL_DEALING} />
            </>
          )
          break;
        // case 'DEBT CAPITAL MARKETS':
        //   setChildrenTab(
        //     <>
        //       <ListFile data={data.DEBT_CAPITAL_MARKETS} />
        //     </>
        //   )
        //   break;
        //  case 'COMUNICATI STAMPA':
        //    setChildrenTab(
        //      <>
        //        <EventiComunicati data={data.ComunicatiStampaPriceSensitive} />
        //      </>
        //    )
        //    break;
          case 'COMUNICATI STAMPA PRICE SENSITIVE':
          setChildrenTab(
            <>
              <div className='mt-5' style={{ height: '100vh' }}>
                <iframe 
                  title='Comunicati Price Sensitive'
                  src="https://www.1info.it/SOCIETA/comunicati.aspx?code=00423480482" 
                  style={{ width: '100%', height: '100%', border: 'none' }} 
                />
              </div>
            </>
          )
          break;
          case 'COMUNICATI STAMPA':
            setChildrenTab(
              <>
                <EventiComunicati data={docs.ComunicatiStampa} />
              </>
            )
            break;
          case 'PRESENTAZIONI AZIENDALI':
            setChildrenTab(
              <>
                <p>Nessun dato</p>
              </>
            )
            break;
          // case 'EVENTI SOCIETARI':
          //   setChildrenTab(
          //     <>
          //       <Eventi data={data.EventiSocietari} />
          //     </>
          //   )
          //   break;
          case 'BILANCI E RELAZIONI':
            setChildrenTab(
              <>
                <Faq id="faqBilanci" data={docs.BilanciRelazioni} />
              </>
            )
            break;
          case 'ASSEMBLEE DEGLI AZIONISTI':
            setChildrenTab(
              <>
                <Faq id="faqBilanci" data={docs.Assemblee} />
              </>
            )
            break;
          case 'CALENDARIO FINANZIARIO':
            setChildrenTab(
              <>
                <EventiComunicati data={docs.CalendarioFinanziario} />
              </>
            )
            break;
          case 'INFO PER GLI AZIONISTI':
            setChildrenTab(<InfoAzionisti />)
            break;
          case 'CONTATTI INVESTOR RELATOR':
            setChildrenTab(
              <>
                <ContattiInvestor />
              </>
            )
            break;
          case 'COVERAGE':
              setChildrenTab(
                <>
                  <label>Nessun dato</label>
                </>
              )
              break;
            case 'IPO':
                setChildrenTab(
                  <>
                    <IPO />
                  </>
                )
                break;
          case 'WARRANT':
            setChildrenTab(
              <>
                <label>Nessun dato</label>
              </>
            )
            break;
          default:
            setChildrenTab(
              <>
                <p>Nessun dato</p>
              </>
            )
            break;
      }
    }
    getTab()
  }, [tab])

  useEffect(() => {
    if(cookieConsent === 'false' || !cookieConsent) return
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, [cookieConsent]);

  return (
    <>
      <Helmet>
        <title>Investor Relations - Bertolotti S.p.A.</title>
      </Helmet>
      <NavbarComponent />
      <Container className='mt-5 pt-5'>
        <Row className='gx-4'>
          <Col md='3'>
            <LateralMenu setTab={setTab} data={dataLateralMenu} />
          </Col>
          <Col md='9'>
            <div className='mt-5 pt-2'>
              <label style={{ fontSize: '26px' }} dangerouslySetInnerHTML={{ __html: tab }} />
              <div style={{ height: '4px', width: '50px', background: '#C00322' }} />
            </div>
            <div className='mt-4'>
              {childrenTab}
            </div>
          </Col>
        </Row>
      </Container>
      <Footer />  
    </>
  );
}

export default InvestorRelator;

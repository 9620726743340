import { useEffect } from "react";
import Footer from "../components/Footer";
import NavbarComponent from "../components/NavbarComponent";
import VideoComponent from "../components/VideoComponent";

import ReactGA from "react-ga4";
import { useLanguageContext } from "../Context/LanguageContext";

export default function VideoPage({ link }) {

    const { cookieConsent } = useLanguageContext();

    useEffect(() => {
        if(cookieConsent === 'false' || !cookieConsent) return
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, [cookieConsent]);

    return (
        <div>
            <NavbarComponent />
            <div className="py-5" style={{ height: '100vh' }}>
                <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                    <div className="h-100 w-50 mt-5">
                        <VideoComponent 
                            link={link}
                        />
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
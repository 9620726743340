import React from "react";
import { Col, Row } from "react-bootstrap";
import { useLanguageContext } from "../Context/LanguageContext";

export default function OrganiSociali({ consiglio, collegio, societa }) {

    const { language } = useLanguageContext();

    const stampaCardConsiglio = consiglio.map((p, index) => {
        return (
            <>
                <Col md='4' className="mt-4">
                    {/* <div>
                        <Image src={p.imgSrc.match(/^(http(s):\/\/.)[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/g) ? p.imgSrc : require('./../assets/Images/' + p.imgSrc)} className="img-fluid" />
                    </div> */}
                    <div className="mt-3">
                        <label style={{ color: '#C00322', fontSize: '24px', fontWeight: 'bold' }}>{p.nome}</label>
                    </div>
                    <div className="mt-2" style={{ height: '50px' }}>
                        <label>{window.location.pathname !== '/en' ? p.ruolo : p.ruoloEN}</label>
                    </div>
                    <div className="mt-3">
                        <button className="buttonBio px-3 py-1" onClick={() => { window.open(language !== 'en' ? p.link : p.linkEN) } }>
                            BIO
                        </button>
                    </div>
                </Col>
            </>
        )
    })

    const stampaCardCollegio = collegio.map((p, index) => {
        return (
            <>
                <Col md='4' className="mt-4">
                    {/* <div className="d-flex align-items-center" style={{ height: '450px' }}>
                        <Image src={p.imgSrc.match(/^(http(s):\/\/.)[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/g) ? p.imgSrc : require('./../assets/Images/' + p.imgSrc)} className="img-fluid" />
                    </div> */}
                    <div className="mt-3">
                        <label style={{ color: '#C00322', fontSize: '24px', fontWeight: 'bold' }}>{p.nome}</label>
                    </div>
                    <div className="mt-2" style={{ height: '50px' }}>
                        <label>{window.location.pathname !== '/en' ? p.ruolo : p.ruoloEN}</label>
                    </div>
                    <div className="mt-3">
                        <button className="buttonBio px-3 py-1" onClick={() => { window.open(language !== 'en' ? p.link : p.linkEN) } }>
                            BIO
                        </button>
                    </div>
                </Col>
            </>
        )
    })

    const stampaCardSocieta = societa.map((p, index) => {
        return (
            <>
                <Col className="mt-4">
                    {/* <div className="d-flex align-items-center" style={{ height: '450px' }}>
                        <Image src={p.imgSrc.match(/^(http(s):\/\/.)[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)$/g) ? p.imgSrc : require('./../assets/Images/' + p.imgSrc)} className="img-fluid" />
                    </div> */}
                    <div className="mt-3">
                        <label style={{ color: '#C00322', fontSize: '24px', fontWeight: 'bold' }}>{p.nome}</label>
                    </div>
                    <div className="mt-2" style={{ height: '50px' }}>
                        <label>{window.location.pathname !== '/en' ? p.ruolo : p.ruoloEN}</label>
                    </div>
                    {/* <div className="mt-3">
                        <button className="buttonBio px-3 py-1" onClick={() => { window.open(window.location.pathname !== '/en' ? p.link : p.linkEN) } }>
                            BIO
                        </button>
                    </div> */}
                </Col>
            </>
        )
    })

    return (
        <>
            {/*<div className="mt-5">
                <div className="w-100 mb-2 d-flex justify-content-center">
                    <label style={{ fontSize: '24px' }}>
                        CORPORATE GORVERNANCE
                    </label>
                </div>
                <div className="mt-3">
                    <div className="mt-3">
                        <label>
                            La società adotta un sistema tradizionale di amministrazione e controllo. 
                        </label>
                    </div>
                    <div className="mt-3">
                        <label>
                            Il Consiglio di Amministrazione è stato nominato dall’assemblea in data 16 luglio 2021 e rimarrà in carica sino all’approvazione del bilancio di esercizio al 31 dicembre 2023. In data 17 maggio 2023 l’assemblea ha preso atto delle dimissioni di un consigliere nominando in sostituzione Laura Catalani.
                        </label>
                    </div>
                    <div className="mt-3">
                        <label>
                            Il Collegio sindacale è stato nominato dall’assemblea in data 16 luglio 2021 e rimarrà in carica sino all’approvazione del bilancio di esercizio al 31 dicembre 2023. 
                        </label>
                    </div>
                </div>
            </div>*/}
            <div className="mt-5 text-center">
                <div>
                    <label style={{ fontSize: '24px' }}>
                        CONSIGLIO D'AMMINISTRAZIONE
                    </label>
                </div>
                <div className="pb-3">
                    <Row>
                        {stampaCardConsiglio}
                    </Row>
                </div>
            </div>
            <div className="mt-5 text-center">
                <div>
                    <label style={{ fontSize: '24px' }}>
                        COLLEGIO SINDACALE
                    </label>
                </div>
                <div className="pb-3">
                    <Row>
                        {stampaCardCollegio}
                    </Row>
                </div>
            </div>
            <div className="mt-5 text-center">
                <div>
                    <label style={{ fontSize: '24px' }}>
                        SOCIETÀ DI REVISIONE
                    </label>
                </div>
                <div>
                    <Row>
                        {stampaCardSocieta}
                    </Row>
                    <Row><small>L’incarico di revisione legale dei conti è stato conferito a BDO Italia S.p.A., con sede legale in Milano, Viale Abruzzi n. 94, sino alla data dell’Assemblea convocata per l’approvazione del bilancio di esercizio di Bertolotti S.p.A. al 31 dicembre 2025.</small></Row>
                </div>
            </div>
            {/*<div className="mt-2">
                <div onClick={() => { window.open('https://drive.google.com/u/1/uc?id=104KyV8l8MYepBV9Mm6nuDtDKbegk9TGd&export=download') }} className="d-flex flex-row py-4 align-items-center">
                    <div className="ps-3 w-75 d-flex align-items-center">
                        <label><strong>Codice Etico</strong></label>
                    </div>
                    <div className="pe-2 w-25 d-flex justify-content-end align-items-center">
                        <MdOutlineDownloading size={35} style={{ color: '#C00322' }} />
                    </div>
                </div>
            </div>*/}
        </>
    )
}
import React from "react";
import { MdOutlineDownloading } from 'react-icons/md'
import { useLanguageContext } from "../Context/LanguageContext";


export default function ListFileTitle({
    data
}) {

    const { language } = useLanguageContext() 

    const stampaMacroCategorie = data.map((p, index) => {
        const stampaDocumento = p.list.map((d, index) => {

            const stampaSingleDoc = d.listDoc.map((k, index) => {
                return (
                    <>
                        <div onClick={() => { window.open(k.linkDocumento) }} style={{ borderBottom: '1px solid #DBDBDB' }} className="d-flex flex-row py-4 align-items-center">
                            <div className="ps-3 w-75 d-flex align-items-center">
                                <label>{k.titoloDoc}</label>
                            </div>
                            <div className="pe-2 w-25 d-flex justify-content-end align-items-center">
                                <MdOutlineDownloading size={35} style={{ color: '#C00322' }} />
                            </div>
                        </div>
                    </>
                )
            })

            return (
                <>
                    {
                        d.listDoc.length === 0 ? (
                            <>
                                <div onClick={() => { window.open(d.linkDocumento) }} style={{ borderBottom: '1px solid #DBDBDB' }} className="d-flex flex-row py-4 align-items-center">
                                    <div className="ps-3 w-75 d-flex align-items-center">
                                        <label>{language === 'it' ? d.titoloDocumento : d.titoloDocumentoEN ? d.titoloDocumentoEN : d.titoloDocumento}</label>
                                    </div>
                                    <div className="pe-2 w-25 d-flex justify-content-end align-items-center">
                                        <MdOutlineDownloading size={35} style={{ color: '#C00322' }} />
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className="mt-4 ms-5">
                                    <div style={{ borderBottom: '1px solid black' }}>
                                        <h3>{d.titoloDocumento}</h3>
                                    </div> 
                                    <div>
                                        {stampaSingleDoc}
                                    </div>
                                </div>
                            </>
                        )
                    }
                </>
            )
        })

        return (
            <>
                <div className="mt-4">
                    <div style={{ borderBottom: '1px solid black' }}>
                        <h3>{p.title}</h3>
                    </div> 
                    <div>
                        {stampaDocumento}
                    </div>
                </div>
            </>
        )
    })

    return (
        <>
            <div>
                {stampaMacroCategorie}
            </div>
        </>
    )
}
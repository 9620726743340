import { Container } from "react-bootstrap";
import { useLanguageContext } from "../../Context/LanguageContext";

export default function Horizon() {

    const { language } = useLanguageContext(); 

    return (
        <div className="bg-light">
        <Container className="p-5">
            <div className="d-flex justify-content-between">
                
                {
                language === 'en' ? (
                    <strong style={{ fontSize: '48px' }}>
                        Our nest: Italy<br />Our horizon: the world
                    </strong>
                ) : (
                    <strong style={{ fontSize: '48px' }}>
                        Il nostro nido: l'Italia<br />Il nostro orizzonte: il mondo
                    </strong>
                )
                }
            </div>
            <div className="row mt-5 pt-4 d-flex align-items-center">
                <div className="col-md-6">
                    <div>
                        <ul className="p-0 ms-3">
                            <li>
                                <strong>BUSINESS UNITS: 30</strong>
                            </li>
                            <li>
                                <strong>OPERATING COMPANIES: 3 + 1 holding</strong>
                            </li>
                            <li>
                                <strong>FOREIGN BRANCHES: 3 countries</strong>
                            </li>
                            <li>
                                <strong>EMPLOYEES: 114</strong>
                            </li>
                        </ul>
                    </div>
                    <div className="mt-3">
                        <label style={{ color: '#C01725' }}>
                        {
                            language === 'en' ? `OUR OFFICES IN THE WORLD`
                            : `BERTOLOTTI NEL MONDO`
                        }
                        </label>
                    </div>
                    <div className="mt-4">
                        <div>
                            <strong>
                            {
                                language === 'en' ? `Headquarters in:`
                                : `Sede:`
                            }
                            </strong>
                        </div>
                        <div className="mt-3">
                            <label>
                                Località  Sant'Antonio, snc, 50063,  Figline e Incisa Valdarno,  Firenze - Italia
                            </label>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div>
                            <strong>
                                {
                                    language === 'en' ? `R&D centre in:`
                                    : `Centro R&D in:`
                                }
                            </strong>
                        </div>
                        <div className="mt-3">
                            <label>
                                Cascina-Pisa
                            </label>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div>
                        <strong>
                            {
                                language === 'en' ? `New Hub in:`
                                : `Nuovo Hub in:`
                            }
                        </strong>
                        </div>
                        <div className="mt-3">
                            <label>
                                Pontassieve which in its first step will be operational in 2024
                            </label>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div>
                        <strong>
                            {
                                language === 'en' ? `New Offices in:`
                                : `Nuovi uffici in:`
                            }
                        </strong>
                        </div>
                        <div className="mt-3">
                            <label>
                                Blair Street  Haselwood green  Pittsbourh (2024)
                            </label>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div>
                            <strong></strong>
                        </div>
                        <div className="mt-3">
                            <label>
                                Rue du  Lieutenand Petit  Leroy 94550  Chevilly Laurue (2024)
                            </label>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div>
                            <strong></strong>
                        </div>
                        <div className="mt-3">
                            <label>
                                New Delhy - Noida  (2024) TBD
                            </label>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mt-5 mt-md-0">
                    <img src="/assets/img/horizon.png" className="img-fluid" alt="horizon" />
                </div>
            </div>
        </Container>
        </div>
    )
}
import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { MdOutlineDownloading } from "react-icons/md";
import { useLanguageContext } from "../Context/LanguageContext";

export default function IPO() {

    const [check, setCheck] = useState(false)
    const [stampa, setStampa] = useState()

    const { language } = useLanguageContext()

    useEffect(() => {
        if(language === 'it') {
            setStampa(
                <>
                    <div className="mt-4">
                        <div>
                            <label>
                        Per poter accedere alla presente sezione del sito web è necessario leggere e accettare l’informativa di 
                        seguito riportata, che il lettore deve valutare attentamente prima di leggere, accedere o utilizzare in 
                        qualsiasi altra maniera le informazioni di seguito fornite. Accedendo alla presente sezione del sito web, si 
                        accetta di essere soggetti ai termini e alle condizioni di seguito riportati, che potrebbero essere modificati o 
                        aggiornati (e, per tale ragione, dovranno essere letti integralmente ogni volta che vi si accede).
                            </label>
                        </div>
                        <div className="mt-4">
                            <label>
                        Il documento di ammissione pubblicato nella presente sezione del sito web (“<strong>Documento di Ammissione</strong>”) 
                        è stato redatto in conformità al Regolamento Emittenti dell’Euronext Growth Milan (“<strong>Regolamento 
                        Emittenti</strong>”) ai fini dell’ammissione delle azioni ordinarie di Bertolotti S.p.A. - Impianti per l’industria siderurgica e mineraria (le “<strong>Azioni</strong>” e la “<strong>Società</strong>”) su tale 
                        sistema multilaterale di negoziazione organizzato e gestito da Borsa Italiana S.p.A.
                            </label>
                        </div>
                        <div className="mt-4">
                            <label>
                                L’emissione delle Azioni contemplata nel Documento di Ammissione e ogni altra informazione contenuta 
                                nelle seguenti pagine non costituiscono una “offerta al pubblico”, così come definita dal Decreto Legislativo 
                                24 febbraio 1998, n. 58 (“<strong>TUF</strong>”), e pertanto non si rende necessaria la redazione di un prospetto secondo gli 
                                schemi previsti Regolamento Delegato (UE) 2019/980. Il Documento di Ammissione non costituisce 
                                pertanto un prospetto e la sua pubblicazione non deve essere autorizzata dalla CONSOB ai sensi del 
                                Regolamento (UE) 2017/1129 o di qualsiasi altra norma o regolamento disciplinante la redazione e la 
                                pubblicazione dei prospetti informativi ai sensi degli articoli 94 e 113 del TUF, ivi incluso il regolamento 
                                emittenti adottato dalla CONSOB con delibera n. 11971 del 14 maggio 1999, come successivamente 
                                modificato e integrato. Le informazioni contenute nella presente sezione del sito web sono diffuse in 
                                conformità agli articoli 17 e 26 del Regolamento Emittenti.
                            </label>
                        </div>
                        <div className="mt-4">
                            <label>
                                La presente sezione del sito web, il Documento di Ammissione e ogni altra informazione contenuta nelle 
                                seguenti pagine sono accessibili solo da soggetti che: (i) sono residenti in Italia e/o in un altro Paese 
                                dell’Unione Europea e non sono domiciliati né comunque si trovano attualmente negli Stati Uniti d’America, 
                                Australia, Giappone, Canada nonché in qualsiasi altro Paese in cui la diffusione del Documento di 
                                Ammissione e/o di tali informazioni richieda l’approvazione delle competenti Autorità locali o sia in 
                                violazione di norme o regolamenti locali (“<strong>Altri Paesi</strong>”); e (ii) non sono “U.S. Person”, secondo la definizione 
                                contenuta nella Regulation S dello United States Securities Act del 1933, come successivamente modificato, 
                                né sono soggetti che agiscono per loro conto o a loro beneficio senza l’esistenza di un’apposita registrazione 
                                o di una specifica esenzione alla registrazione prevista ai sensi dello United States Securities Act e della 
                                normativa vigente.
                            </label>
                        </div>
                        <div className="mt-4">
                            <label>
                                Alle “U.S. Person” nel senso sopra indicato è preclusa ogni possibilità di accesso alla presente sezione del 
                                sito web, di download, memorizzazione e/o salvataggio temporaneo o duraturo del Documento di 
                                Ammissione e di ogni altra informazione contenuta nella presente sezione del sito web. Le informazioni 
                                contenute nella presente sezione del sito web non possono essere copiate o inoltrate. Per nessuna ragione 
                                e in nessuna circostanza è consentito far circolare, direttamente o tramite terzi, il Documento di 
                                Ammissione e ogni altra informazione contenuta nella presente sezione del sito web a soggetti che si 
                                trovino nelle condizioni di cui a punti (i) e (ii) del paragrafo che precede e, in particolare, negli Stati Uniti, in 
                                Australia, in Giappone, in Canada o negli Altri Paesi.
                            </label>
                        </div>
                        <div className="mt-4">
                            <label>
                                Le informazioni contenute nel presente sito web (o in qualsiasi altro sito col quale il presente sito web abbia 
                                collegamenti ipertestuali) non costituiscono offerta, invito ad offrire o attività promozionale in relazione alle 
                                Azioni nei confronti di alcun cittadino o soggetto residente negli Stati Uniti, in Australia, Giappone, Canada o 
                                negli Altri Paesi. Inoltre, le Azioni della Società non sono, né saranno, oggetto di registrazione ai sensi dello 
                                United States Securities Act del 1993, così come modificato, o presso alcuna autorità regolamentare di 
                                qualsiasi stato o altra giurisdizione degli Stati Uniti e non potranno essere offerti o venduti negli Stati Uniti o 
                                a, o per conto e beneficio di, una “U.S. Person”, come infra definito, in assenza della predetta registrazione 
                                o di espressa esenzione da tale adempimento, o in Australia, Giappone, Canada o in Altri Paesi.
                            </label>
                        </div>
                        <div className="mt-4">
                            <label>
                                Per accedere alla presente sezione del sito web, al Documento di Ammissione e ad ogni altra informazione 
                                contenuta nelle seguenti pagine, dichiaro sotto la mia piena responsabilità di essere residente in Italia e di 
                                non essere domiciliato né di trovarmi attualmente negli Stati Uniti d’America, in Australia, Giappone, in 
                                Canada o negli Altri Paesi e di non essere una “U.S. Person” come definita nella Regulation S dello United 
                                States Securities Act del 1933, come successivamente modificato.
                            </label>
                        </div>
                        <div id="checkIPO" className="mt-5">
                            <Form.Check
                                defaultValue={check}
                                onChange={() => { setCheck(!check) }}
                                type="checkbox"
                                label="Confermo di aver letto e di accettare i termini e le condizioni di cui sopra"
                                id="checkboxTermsConditions"
                                className="d-flex align-items-center"
                            />
                        </div>
                        <div className="mt-3">
                            <button disabled={!check} onClick={() => { window.open('https://drive.google.com/u/0/uc?id=1d1RpjV2gcvoGx3n_qJjceanPvVtZG_rn&export=download') }} style={{ border: 'none', background: 'transparent' }} className="d-flex flex-row my-4 align-items-center">
                                <div className="pe-2">
                                    <MdOutlineDownloading size={50} style={{ color: '#C00322' }} />
                                </div>
                                <div className="ps-3">
                                    <label>Documento di Ammissione</label>
                                </div>
                            </button>
                        </div>
                    </div>
                </>
            )
        } else {
            setStampa(
                <>
                    <div className="mt-4">
                        <div>
                            <label>
                                In order to access this section of the website, you must read and accept the following notice, which you must consider carefully before reading, accessing or otherwise using the information provided below. By accessing this section of the website you agree to be subject to the following terms and conditions set out below, which may be amended or updated (and, for this reason, should be read in full each time you access it).
                            </label>
                        </div>
                        <div className="mt-4">
                            <label>
                                The admission document published in this section of the website (the "<strong>Admission Document</strong>") has been drafted in compliance with the Issuers Regulation of Euronext Growth Milan (the "<strong>Issuers Regulation</strong>") for the purpose of the admission of the ordinary shares of Bertolotti S.p.A. - Installations for the steel and mining industries (the "<strong>Shares</strong>" and the "<strong>Company</strong>") on that multilateral trading facility organized and managed by Borsa Italiana S.p.A.
                            </label>
                        </div>
                        <div className="mt-4">
                            <label>
                                The issuance of the Shares envisaged in the Admission Document and any other information contained herein does not constitute an "offer to the public" (offerta al pubblico), as defined by Legislative Decree No. 58 of February 24, 1998 ("<strong>Consolidated Law on Finance</strong>"), and therefore it is not mandatory to draft a prospectus in accordance with the formats provided for by Regulation (EU) 2019/980. Accordingly, the Admission Document does not constitute a prospectus, and its publication does not need to be authorized by CONSOB pursuant to Regulation (EU) 2017/1129 or any other rule or regulation governing the drafting and publication of prospectuses, pursuant to Articles 94 and 113 of the Consolidated Law on Finance, including the Issuers’ Regulation adopted by CONSOB with resolution No. 11971 of May 14, 1999, as subsequently amended and supplemented. The information contained in this section of the website is disclosed in accordance with Articles 17 and 26 of the Issuers’ Regulation.
                            </label>
                        </div>
                        <div className="mt-4">
                            <label>
                                This section of the website, the Admission Document and any other information contained herein may only be accessed by persons who: (i) are resident in Italy and/or in another country of the European Union and are not currently domiciled or located in the United States, Australia, Japan, Canada or in any other country where the distribution of the Admission Document and/or such information requires the approval of the competent local authorities, or is in breach of local rules or regulations ("<strong>Other Countries</strong>"); and (ii) are not "U.S. Persons", according to the definition set form in Regulation S of the United States Securities Act of 1933 (the “<strong>Security Act</strong>”), as subsequently amended, nor are persons acting on their behalf or for their benefit, absent registration or an applicable exemption from the registration requirements pursuant to the Securities Act and current legislation.
                            </label>
                        </div>
                        <div className="mt-4">
                            <label>
                                "U.S. Persons" are prohibited from accessing this section of the website, from downloading, storing and/or temporarily or permanently saving the Admission Document and any other information contained in this section of the website. The information contained in this section of the website may not be copied or forwarded. For no reason and under no circumstances may the Admission Document and any other information contained in this section of the website be circulated, directly or through third parties, to persons in the conditions set forth in points (i) and (ii) above and, in particular, in the United States, Australia, Japan, Canada or Other Countries.
                            </label>
                        </div>
                        <div className="mt-4">
                            <label>
                                The information contained in this website (or any other website accessed by hyperlink from this website) does not constitute an offer, solicitation of an offer or promotional activity in relation to the Shares to any citizen or entity resident in the United States, Australia, Japan, Canada or the Other Countries. In addition, the Shares of the Company are not, nor will they be, registered under the Securities Act, or with any regulatory body of any state or other jurisdiction in the United States and may not be offered or sold in the United States or to, or for the account and benefit of a U.S. Person, absent registration or express exemption from such registration requirements, or in Australia, Japan, Canada or Other Countries.
                            </label>
                        </div>
                        <div className="mt-4">
                            <label>
                                By accessing this section of the website, the Admission Document and any other information contained herein, I hereby declare under my full responsibility that I am a resident in Italy and that I am not currently domiciled or located in the United States, Australia, Japan, Canada or Other Countries and that I am not a U.S. Person as defined in Regulation S of the Securities Act.
                            </label>
                        </div>
                        <div id="checkIPO" className="mt-5">
                            <Form.Check
                                defaultValue={check}
                                onChange={() => { setCheck(!check) }}
                                type="checkbox"
                                label="I confirm that I have read and accept the above terms and conditions"
                                id="checkboxTermsConditions"
                                className="d-flex align-items-center"
                            />
                        </div>
                        {/* <div className="mt-4">
                            <div onClick={() => { window.open('https://drive.google.com/u/1/uc?id=1dXDZrMn-dvNJaacDncNlzNpCPjrY4Ngc&export=download') }} style={{ borderBottom: '1px solid #DBDBDB' }} className="d-flex flex-row py-4 align-items-center">
                                <div className="ps-3 w-75 d-flex align-items-center">
                                    <label>Statuto</label>
                                </div>
                                <div className="pe-2 w-25 d-flex justify-content-end align-items-center">
                                    <MdOutlineDownloading size={35} style={{ color: '#C00322' }} />
                                </div>
                            </div>
                        </div> */}
                        <div className="mt-3">
                            <button disabled={!check} onClick={() => { window.open('https://drive.google.com/u/0/uc?id=1d1RpjV2gcvoGx3n_qJjceanPvVtZG_rn&export=download', '_blank') }} style={{ border: 'none', background: 'transparent' }} className="d-flex flex-row my-4 align-items-center">
                                <div className="pe-2">
                                    <MdOutlineDownloading size={50} style={{ color: '#C00322' }} />
                                </div>
                                <div className="ps-3">
                                    <label>Admission Document</label>
                                </div>
                            </button>
                        </div>
                    </div>
                </>
            )
        }
    }, [language, check])

    return (
        <>
            {stampa}
        </>
    )
}
import Footer from "../components/Footer";
import NavbarComponent from "../components/NavbarComponent";

import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLanguageContext } from "../Context/LanguageContext";

import ReactGA from "react-ga4";
import { useEffect } from "react";
import { Helmet } from 'react-helmet';

export default function BertolottiAIWorks() {

    const { language, cookieConsent } = useLanguageContext(); 

    useEffect(() => {
        if(cookieConsent === 'false' || !cookieConsent) return
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, [cookieConsent]);

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 8,
        slidesToScroll: 1,
        centerMode: true,
        autoplay: true,
        speed: 3000,
        cssEase: "linear",
        autoPlaySpeed: 0,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
            }
        ]
    };

    return (
        <>
            {
            language === 'en' ? (
                <Helmet>
                <title>Bertolotti AI Works</title>
                <meta name="description" content="Specialized in software development and the creation of robotic systems." />
                </Helmet>
                ) : (
                <Helmet>
                <title>Bertolotti AI Works</title>
                <meta name="description" content="Specializzata nello sviluppo di software e nella creazione di sistemi robotici." />
                </Helmet>
                )
            }
            <NavbarComponent />
            <div style={{ height: '100vh', backgroundImage: 'url(/assets/img/bertolottiaiworks_header.png)', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <div style={{ textAlign: 'center' }} className="mx-auto d-flex align-items-center h-100">
                    <div className="container w-100">
                        <h1 className="h1-resp text-white">Bertolotti AI Works</h1>
                            {
                                language === 'en' ? (
                                    <h3 className="h3-resp text-white">Specialized in software development and the creation<br />of robotic systems</h3>
                                ) : (
                                    <h3 className="h3-resp text-white">Specializzata nello sviluppo di software e nella creazione<br />di sistemi robotici</h3>
                                )
                            }
                        {/*<div>
                            <a onClick={handleChangePage.bind(this, '#contacts')} className="btn btn-danger">
                                {
                                    language === 'en' ? 'Contacts'
                                    : `Contattaci`
                                }
                            </a>
                            </div>*/}
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#F3F6F8' }} className="py-3" >
                <h2 className="text-center py-3 mt-3" style={{color: '#262A30', fontSize:'36px'}}>
                    {
                        language === 'en' ? `Trusted by`
                        : `Scelti da`
                    }
                </h2>
                <Slider {...settings} className="py-4" >
                    <img src="/assets/img/ferretti_logo.png" alt="" ></img>
                    <img src="/assets/img/generalelectric_logo.png" alt="" ></img>
                    <img src="/assets/img/korber_logo.png" alt="" ></img>
                    <img src="/assets/img/rossinavi_logo.png" alt="" ></img>
                    <img src="/assets/img/aselsan_logo.png" alt="" ></img>
                    <img src="/assets/img/francobarberis_logo.png" alt="" ></img>
                    <img src="/assets/img/fincantieri_logo.png" alt="" ></img>
                    <img src="/assets/img/essity_logo.png" alt="" ></img>
                </Slider>
            </div>
            <div className="py-5 mt-5">
                <div className="container my-5">
                    <div className="row g-3 g-lg-5">
                        <div className="col-md-6 d-flex align-items-center">
                            <img src='/assets/img/bertolottiaiworks_roboticsystem.png' alt="bertolotti service" className="img-fluid"></img>
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                            <div>
                                <h2 className="py-2" style={{fontSize: '30px'}}>
                                {
                                    language === 'en' ? `Bertolotti Ai-Works: R&D-dedicated business unit`
                                    : `Bertolotti Ai-Works: Unità aziendale dedicata alla R&D`
                                }
                                </h2>
                                {
                                language === 'en' ? (
                                    <p className="pb-3 pb-md-0">
                                        Founded in July 2023 through the lease of a business unit from Autognity S.r.l (spin-off from the  University of Pisa), Bertolotti Ai-Works specializes in the development and design of software and advanced robotic systems for industrial process automation. Despite being in its initial stage, the unit is strategically positioned to become the company’s R&D core, with  the goal of:
                                        <ul>
                                            <li>bring in-house the more strategic contents and know-how securing direct control and higher  flexibility, in particular for AGVs (automated Guided Vehicles) and intelligent warehouses,</li>
                                            <li>expanding Bertolotti’s current offering by adding robotic systems and cloud-based software  with industrial applications.</li>
                                        </ul>
                                        The team carries R&D projects either related to specific projects as part of clients’ orders or aimed at improving Bertolotti technological solutions. This  new Business Unit has already enabled Bertolotti to secure several tenders, showcasing the innovation  of the proposed projects.
                                    </p>
                                ) : (
                                    <p className="pb-3 pb-md-0">
                                        Nata nel luglio 2023 dall'affitto di un ramo d'azienda di Autognity S.r.l. (spin-off dell'Università di Pisa), Bertolotti Ai-Works è specializzata nello sviluppo e nella progettazione di software e sistemi robotici avanzati per l'automazione dei processi industriali. Pur essendo in fase iniziale, l'unità è strategicamente posizionata per diventare il nucleo di R&S dell'azienda, con l'obiettivo di:
                                        <ul>
                                            <li>portare all'interno dell'azienda i contenuti e il know-how più strategici per garantire un controllo diretto e una maggiore flessibilità, in particolare per gli AGV (automated Guided Vehicles) e i magazzini intelligenti,</li>
                                            <li>ampliare l'attuale offerta Bertolotti aggiungendo sistemi robotici e software cloud-based con applicazioni industriali.</li>
                                        </ul>
                                        Il team porta avanti progetti di ricerca e sviluppo sia legati a progetti specifici nell'ambito delle commesse dei clienti, sia finalizzati al miglioramento delle soluzioni tecnologiche Bertolotti. Questa nuova Business Unit ha già permesso a Bertolotti di aggiudicarsi diverse gare d'appalto, dimostrando l'innovazione dei progetti proposti.
                                    </p>
                                )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: '100vh', backgroundImage: 'url(/assets/img/bertolottiaiworks_phoenixos.png)', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <div style={{ textAlign: 'center' }} className="mx-auto d-flex align-items-center h-100">
                    <div className="container w-100">
                            <label className="text-white px-2 py-1" style={{backgroundColor:'#C01725', borderRadius:'60px', fontSize:'12px'}}>
                            {
                                language === 'en' ? `OPERATING SYSTEM`
                                : `SISTEMA OPERATIVO`
                            }
                            </label>
                            <h2 className="text-white pt-3 pb-2">Phoenix OS</h2>
                            <p style={{color:'#F3F6F8'}}
                            dangerouslySetInnerHTML={{ __html: language === 'en' ? `Phoenix OS is an innovative operating system based on a smartphone-like interface that transforms the management of complex robotic systems into a<br class="d-none d-md-block" />simple and intuitive experience, composed of multiple apps, each for a specific function.<br class="d-none d-md-block" /><br class="d-none d-md-block" />
                            The user interface is structured across multiple pages, which allows for clear and organized navigation.<br class="d-none d-md-block" />The user interface is designed to be intuitive and easy to use, allowing anyone to use the system with ease, even without advanced technical training.<br class="d-none d-md-block" /><br class="d-none d-md-block" />Phoenix OS is accessible via a web browser and can be used on any electronic device with Internet access.`
                            : `Phoenix OS è un sistema operativo innovativo basato su un'interfaccia simile a quella di uno smartphone che trasforma la gestione di sistemi robotici complessi in un'esperienza<br class="d-none d-md-block" />semplice e intuitiva, composta da più app, ciascuno per una funzione specifica.<br class="d-none d-md-block" /><br class="d-none d-md-block" />
                            L'interfaccia utente è strutturata su più pagine, il che consente una navigazione chiara e organizzata.<br class="d-none d-md-block" />L'interfaccia utente è progettata per essere intuitiva e facile da usare, consentendo a chiunque di utilizzare il sistema con facilità, anche senza una formazione tecnica avanzata.<br class="d-none d-md-block" /><br class="d-none d-md-block" />
                            Phoenix OS è accessibile tramite un browser Web e può essere utilizzato su qualsiasi dispositivo elettronico con accesso a Internet.`}}
                            />
                    </div>
                </div>
            </div>
            {/* <div className="py-5 my-5" id="contacts">
                    <div className="container text-center">
                        <h2>
                        {
                            language === 'en' ? 'Contacts'
                            : "Contatti"
                        }
                        </h2>
                        <div className="row my-5 g-3">
                            <div className="col-md-4" >
                                <div className="py-4" style={{backgroundColor:'#F3F6F8', borderRadius:'10px'}}>    
                                    < PiMapPinLine color="#C01725" size={30} />
                                    <p className="mt-3" style={{fontSize:'24px', fontWeight:'600'}}>
                                    {
                                        language === 'en' ? `Location`
                                        : `Posizione`
                                    }
                                    </p>
                                    <p className="mb-0">Località Sant'Antonio<br />50063, Figline e Incisa Valdarno (FI)</p>
                                </div>
                            </div>
                            <div className="col-md-4" >
                                <div className="py-4" style={{backgroundColor:'#F3F6F8', borderRadius:'10px'}}>  
                                    < PiEnvelope color="#C01725" size={30} />
                                    <p className="mt-3" style={{fontSize:'24px', fontWeight:'600'}} >E-mail </p>
                                    <p className="mb-0">
                                    {
                                        language === 'en' ? `Get support via mail:`
                                        : `Contattaci tramite mail a:`
                                    }
                                    <br />info@bertolottigroup.com</p>
                                </div>
                            </div>
                            <div className="col-md-4" >
                                <div className="py-4" style={{backgroundColor:'#F3F6F8', borderRadius:'10px'}}>
                                    < PiPhone color="#C01725" size={30} />
                                    <p className="mt-3" style={{fontSize:'24px', fontWeight:'600'}}>
                                    {
                                        language === 'en' ? `Phone`
                                        : `Telefono`
                                    }
                                    </p>
                                    <p className="mb-0">
                                    {
                                        language === 'en' ? `Contact us by phone:`
                                        : `Chiamaci al:`
                                    }    
                                    <br />+39 055 833171
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            <Footer />
        </>
    )
}
import React from 'react';

const CategorieSelect = ({ categorie, categoriaSelezionata, onCategoriaClick, lang }) => {
  return (
    <div style={{background: 'rgb(38, 42, 48)'}}>
      <div className="container">
        <div className="row">
          {categorie.map((categoria) => (
            <div key={categoria.id} className={`col-md-3 text-center p-4 ${categoriaSelezionata === categoria.id ? 'box-categoria-selezionata' : 'box-categoria'}`} onClick={() => onCategoriaClick(categoria.id)}>
              <div className='py-5'>
                <img src={categoria.icon} className="img-fluid" style={{width: '50px'}} alt={categoria.nome} />
                <div className="card-title-container">
                  <h5>{lang === 'en' ? (categoria.name) : (categoria.nome)}</h5>
                </div>
              </div>
              {/*<small>{lang === 'en' ? (categoria.description) : (categoria.descrizione)}</small>*/}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CategorieSelect;

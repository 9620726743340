import React from "react";
import { FaqProps } from "../utility/interface";
import { Accordion } from "react-bootstrap";
import { MdOutlineDownloading } from "react-icons/md";

export default function Faq({ data, id }: FaqProps) {


    const stampaFaqItem = data.map((p, index) => {

        const stampaFaqChild = p.list.map((d, index) => {
            return (
                <>
                    <div>
                        <div onClick={() => { window.open(d.link) }} className="d-flex flex-row py-4 align-items-center">
                            <div className="ps-3 w-75 d-flex align-items-center">
                                <label dangerouslySetInnerHTML={{ __html: d.titleDocument }} />
                            </div>
                            <div className="pe-2 w-25 d-flex justify-content-end align-items-center">
                                <MdOutlineDownloading size={35} style={{ color: '#C00322' }} />
                            </div>
                        </div>
                    </div>
                </>
            )
        })

        return (
            <>
                <Accordion.Item eventKey={String(index)}>
                    <Accordion.Header>
                        <div className="w-100">
                            <div>
                                <label style={{ fontSize: '24px' }}>
                                    {p.title}
                                </label>
                            </div>
                            <div className="d-flex justify-content-end w-100">
                                <label className="me-4" style={{ color: '#C00322' }}>
                                    {p.list.length} {p.list.length > 1 ? 'allegati' : 'allegato'}
                                </label>
                            </div>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        {stampaFaqChild}
                    </Accordion.Body>
                </Accordion.Item>
            </>
        )
    })

    return (
        <>
            <div id={id}>
                <Accordion>
                    {stampaFaqItem}
                </Accordion>
            </div>
        </>
    )
}
import React from "react";
import { useLanguageContext } from "../Context/LanguageContext";
import InfoAzionistiITA from "./InfoAzionistiComponent/InfoAzionistiITA";
import InfoAzionistiENG from "./InfoAzionistiComponent/InfoAzionistiENG";

export default function InfoAzionisti() {

    // const [linkDocumento, setLinkDocumento] = useState('')

    // useEffect(() => {
    //     if(window.location.pathname === '/') {
    //         setLinkDocumento('https://drive.google.com/u/1/uc?id=17dnZCWEbcppAC2LGCGCaeGDuqYhVNDMG&export=download')
    //     } else {
    //         setLinkDocumento('https://drive.google.com/u/1/uc?id=1AM8m8kL8Yu7vQsmdTVYE6FWMp2zIdZLZ&export=download')
    //     }
    // }, [])

    const { language } = useLanguageContext()

    if(language === 'en') {
        return <InfoAzionistiENG />
    }

    return <InfoAzionistiITA />
}
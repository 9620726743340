import Footer from "../components/Footer";
import NavbarComponent from "../components/NavbarComponent";

import Slider from "react-slick"
import { useLanguageContext } from "../Context/LanguageContext";
import { Helmet } from 'react-helmet';

import ReactGA from "react-ga4";
import React, { useState, useEffect } from 'react';
import CategorieSelect from "../components/CategorieSelect";
import ProdottoCard from "../components/ProdottoCard";

export default function BertolottiRail() {

    const { language, cookieConsent } = useLanguageContext();

    useEffect(() => {
        if(cookieConsent === 'false' || !cookieConsent) return
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, [cookieConsent]);

    const categorie = [
        { 
            id: 'Soluzioni sollevamento', 
            nome: 'Soluzioni sollevamento', 
            name: 'Lifting Solutions', 
            descrizione: 'Sistemi di montaggio/smontaggio del carrello (calacarrelli), assili e altri componenti. Dispositivi di rotazione del telaio del carrello per ispezioni. Sistemi cala-assi per una facile sostituzione degli assili. Colonne di sollevamento (cavalletti standard e personalizzabili per operazioni di manutenzione versatili).', 
            description: 'Bogie lowering systems (or bogie drop) for component replacement. Bogie frame rotating devices for efficient inspections. Axle drop systems for easy axle replacement. Standard andtailor made lifting jacks for versatile maintenance operations.', 
            icon: '/assets/img/cala_carrelli.png' 
        },
        { 
            id: 'Piattaforme per accesso al tetto del treno (Imperiale)', 
            nome: 'Piattaforme per accesso al tetto del treno (Imperiale)', 
            name: 'Platforms for roof access', 
            descrizione: 'Le piattaforme di lavoro fisse sono strutture permanenti che forniscono un accesso stabile, sicuro e personalizzato al tetto per i lavori di manutenzione. Le piattaforme di lavoro mobili sono piattaforme che possono essere posizionate a fianco del treno, offrendo un accesso al tetto flessibile e versatile in varie posizioni', 
            description: 'Fixed working platforms are permanent structuresto the structure of the depot to provide stable and customized access to the roof for maintenance work. Mobile working platforms are movable platforms that can be positioned alongside the train, offering flexible and versatile roof access in various locations', 
            icon: '/assets/img/sollevatori.png' 
        },
        { 
            id: 'Sistemi di movimentazione', 
            nome: 'Sistemi di movimentazione', 
            name: 'Handling Systems', 
            descrizione: 'Sezioni di binario rialzate apribili per un facile accesso alla parte inferiore del treno. Tavoli di trasferimento dei carrelli per una comoda rimozione degli stessi. Carrelli ausiliari con sistemi di sospensione regolabili. Veicoli versatili in grado di effettuare diversi movimenti e movimentare carichi pesanti. Trasbordatori con elevate capacità di carico per operazioni di deposito efficienti e soluzioni complete per la ristrutturazione dei depositi che integrano varie attrezzature e componenti infrastrutturali.',
            description: 'Elevated rotating track sections for easy access to the lower part of the coach. Bogie transfer tables for easy removal of bogies. Auxiliary bogies with adjustable height of the supporting interface. Special vehicles with various movements and heavy load handling. Traversers with high load capacities for efficient depot operations, and comprehensive depot renovation solutions integrating various equipment and infrastructure components.',
            icon: '/assets/img/passerelle_mobili.png' 
        },
        { 
            id: 'Sistemi di rifornimento sabbia', 
            nome: 'Sistemi di rifornimento sabbia', 
            name: 'Sanding Systems', 
            descrizione: 'Impianti fissi o unità mobili utilizzate per riempire di sabbia le sabbiere del materiale rotabile. I sistemi di iniezione sabbia sono installati sui treni per migliorare le prestazioni di trazione e di frenatura grazie al rilascio controllato di sabbia sui binari, migliorando l\'aderenza in condizioni climatiche avverse.', 
            description: 'Fixed or mobile units used to refill sand into sandboxes of rolling stock. Sand boxes are installed on the bogies of the trains to improve traction and breaking cperformance by providing controlled sand release onto the tracks, enhancing friction with bad weather conditions.', 
            icon: '/assets/img/impianti_sabbia.png'
        },
    ];

    const prodottiIniziali = [
        {
            id: 1, 
            nome: "Cala assi", 
            name: "Axle drop", 
            descrizione: "I sistemi di cala assi possono essere proposti per sostituire assali su rotabili elettrico o diesel (EMU o DMU), treni ad alta velocità o locomotive di grandi dimensioni, con capacità di passaggio da 15 a 25 tonnellate per asse, con carrelli a 2 o 3 assi.",
            description: "The axle drop can be proposed to replace axles on electric or diesel rolling stock (EMU or DMU), high-speed trains or large locomotives, with passage capacity on it from 15 tons to 25 tons per axle, with bogie with 2 or 3 axles.", 
            categoria: "Soluzioni sollevamento",
            img: "/assets/img/cala_assi.jpg",
            icon: "/assets/img/cala_carrelli.png"
        },
        {
            id: 2,
            nome: "Cala carrelli", 
            name: "Bogie drop", 
            descrizione: "I sistemi cala carrelli con funzione integrata di sostituzione assili e componenti sotto cassa, sono molto apprezzati per i sistemi metropolitani con capacità di passaggio di 30 tonnellate (da 10 a 16 tonnellate di capacità per asse) ma anche per il cambio dei carrelli per treni EMU, alta velocità o locomotive di grandi dimensioni, con capacità di passaggio da 15 a 25 tonnellate di portata per asse. La macchina può essere progettata da una capacità di sollevamento minima di 10 tonnellate fino a 50 tonnellate di capacità di sollevamento o più, se necessario. Con il sistema cala carrelli a estrazione laterale, a fossa ribassata, è possibile rimuovere il carrello, gli assili e i componenti sottoscocca con interfacce dedicate. Tre attività con una sola macchina! Possiamo fornire Cala Carrelli anche per locomotori a 3 assi fino a 200 tons.", 
            description: "The bogie drops with integrated function for replacing axles and components under the train body are very popular for metro systems with a passage capacity of 30 tons (from 10 to 16 tons of capacity per axle) but also for changing bogies for EMU trains, high speed, or large locomotives, with passage capacity from 15 tons to 25 tons of capacity per axle. The machine can be designed from a minimum lifting capacity of 10 tons up to 50 tons of lifting capacity or more if needed. With the bogie drop with low pit and lateral extraction, it is possible to remove the bogie, the axles and the underbody components with dedicated interfaces. Three activities with one machine! We can also supply bogie drop for 3-axles locomotives up to 200 tons.", 
            categoria: "Soluzioni sollevamento",
            img: "/assets/img/cala_carrelli.jpg",
            icon: "/assets/img/cala_carrelli.png"
        },
        {
            id: 3, 
            nome: "Sollevatori a colonna",
            name: "Lifting jacks",
            descrizione: "Each packaging line is tailor made for each individual project (type of packaging, material flow, lay-out constraints) and individual customer's needs. Packaging facilities are included in the overall logistics concept to increase the productivity and efficiency of the packaging department; optimally organizing material flow and speed-up of packing tasks are key objectives.", 
            description: "The customer can choose a standard product or ask specifically for his custom-made jack, according to the needs he may have in the warehouse. Our lifting jacks can be designed for trams, subways, passenger trains and locomotives, from 6 to 50 tons each, from a minimum of 4 columns to 48 columns. They can be mobile with rubber wheels, with fixed or retractable anvil with movement on rails. Our lifting systems comply with the most restrictive standards in terms of safety and, in particular, are designed following the harmonized standards referred to in Annex IV of the new Machine Directive 2006/42 / EC on lifting systems for vehicles, in particular the standard UNI EN 1493 or NR / L2 / RVE / 0136 for the United Kingdom. For the best level of flexibility, we are able to provide a wireless lifting system where each column is controlled and managed via a secure Wi-Fi network.", 
            categoria: "Soluzioni sollevamento",
            img: "/assets/img/sollevatori_a_colonna.jpg",
            icon: "/assets/img/cala_carrelli.png"
        },
        {
            id: 4, 
            nome: "Bogie rotator", 
            name: "Ribaltatore carrelli", 
            descrizione: "Il ribaltatore è progettato per sollevare e ruotare un telaio del carrello o il carrello completo all'altezza e alla posizione opportune per consentire ispezioni e interventi di manutenzione specifici da parte dell'operatore. Abbiamo al momento tre modelli standard, per sollevare telai fino a 3,5 tons, carrelli o telai fino a 8,5 tons e carrelli completi fino a 16 tons. E' sempre possibile progettare macchine diverse rispondenti alle esigenze di ciascun cliente.", 
            description: "The bogie or bogie frame rotating device is designed to lift and rotate a bogie frame or the complete bogie to the appropriate height and position to allow for specific inspections and maintenance by the operator. We currently have three standard models, to lift frames up to 3.5 tons, trolleys or frames up to 8.5 tons and complete trolleys up to 16 tons. It is always possible to design different machines to meet the needs of each customer.", 
            categoria: "Soluzioni sollevamento",
            img: "/assets/img/ribaltatore_carrelli.jpg",
            icon: "/assets/img/cala_carrelli.png"
        },
        {
            id: 5, 
            nome: "Sollevatori idraulici per spessoramento carrelli", 
            name: "Hydraulic jacks for shimming the springs", 
            descrizione: "I sistemi di sollevamento a cilindri idraulici sono forniti per inserire gli spessori nelle ruote dei tram, una volta che sono state tornite le ruote. La fornitura comprende normalmente 2 o 4 cilindri idraulici con corsa variabile da 500 mm a 1000 mm completi di centrale oleodinamica di controllo. I cilindri son posizionati in una fossa, in posizione opportuna normalmente definita in fase di progettazione.", 
            description: "Lifting systems with hydraulic cylinders are provided for shimming the springs of the tramway wheels, just after the machining of an underfloor wheel lathe. The supply normally includes 2 or 4 hydraulic cylinders with variable stroke from 500 mm to 1000 mm, complete with hydraulic control unit. The cylinders are positioned in a pit, in a suitable position normally defined during the design phase.", 
            categoria: "Soluzioni sollevamento",
            img: "/assets/img/calage.jpg",
            icon: "/assets/img/cala_carrelli.png"
        },
        {
            id: 6, 
            nome: "Sollevatori a scomparsa", 
            name: "Pit lifting jacks", 
            descrizione: "I sollevatori a scomparsa sono progettati per permettere il sollevamento del treno in composizione bloccata e la successiva rimozione di un carrello o di tutti i carrelli allo stesso tempo. I vantaggi dell'impianto sono che una volta utilizzato vengono reinseriti nella fossa e la zona de deposito interessata risulta transitabile da persone e muletti, senza alcun impedimento.", 
            description: "The pit lifting jacks are designed to lift the complete train and remove all the bogies at the same time or one by one, according to the activities. The advantage of the system is that once the system is not used, this can be hidden in pit and it is possible to use the area by people and forklifts, without any constraint. The pit lifting jacks are normally provided form EMU of at least 3 coaches up to 8 or 10 coaches, that means a system from 6 pits up to 20 pits, considering each coach having two bogies. Each “pit” is normally composed by a lower station, from which the coach can be lifted from the wheels and two or four supporting jacks for the coach. Lifting jacks in pit are motorized, with screw nut system that allow complete synchronization during lifting.", 
            categoria: "Soluzioni sollevamento",
            img: "/assets/img/pit_jacks_2.jpg",
            icon: "/assets/img/cala_carrelli.png"
        },
        {
            id: 7, 
            nome: "Passerelle fisse di accesso al tetto", 
            name: "Fixed platforms", 
            descrizione: "Le piattaforme di accesso al tetto dei rotabili sono progettate per fornire un accesso sicuro al tetto del treno per fare manutenzione corrente o ispezione rapida. Le piattaforme di lavoro sono strutture in carpenteria con moduli preassemblati, dotati di coperture mobili motorizzate per chiudere tutti gli spazi tra il treno e la parte fissa della piattaforma. Con opportuni cancelli alle due estremità del rotabile, l'operatore può accedere in quota, in completa sicurezza, una volta completato la procedura di chiusura della macchina. Ogni passerella è progettata nel rispetto delle esigenze di ciascun cliente in base alle caratteristiche del proprio deposito. Le passerelle possono essere fornite con colonne di fissaggio a terra o con una struttura appesa di supporto dei moduli automatici. Integrate con le passerelle possono essere fornite la catenaria retrattile o sistemi di sollevamento per i moduli da smontare (paranchi o gru mobili).", 
            description: "The rolling stock roof access platforms are designed to provide safe access to the train roof for routine maintenance or quick inspection. The working platforms are carpentry structures with pre-assembled modules, equipped with motorized mobile covers to close all the spaces between the train and the fixed part of the platform (sliding or tilting covers). With appropriate protections at both ends of the rolling stock, the operator can access at a height, in complete safety, once the machine closing procedure has been completed. Each platform is designed in compliance with the needs of each customer based on the characteristics of their warehouse. The platforms can be supplied with columns fixed to the ground or with a hanging structure to support the automatic modules. The retractable catenary, hoists or mobile cranes can be supplied integrated with the walkways.", 
            categoria: "Piattaforme per accesso al tetto del treno (Imperiale)",
            img: "/assets/img/passerelle_accesso_al_tetto.jpg",
            icon: "/assets/img/sollevatori.png"
        },
        {
            id: 8, 
            nome: "Passerelle mobili", 
            name: "Movable platforms Bertup", 
            descrizione: "Le passerelle mobili sono normalmente scorrevoli su binari a terra. Il piano di lavoro è sollevabile da una quota di riposo ( circa 900 mm) fino alla quota massima di 5200 mm ( a seconda dei modelli). Bertolotti propone un innovativo sistema di guida libera grazie al quale, usando soltanto una linea colorata a terra, le passerelle si muovono parallele al treno, senza binari. I vantaggi delle passerelle mobili a guida ottica sono innumerevoli. Per i clienti che necessitano di un ingombro a terra ridotto, abbiamo sviluppato una tipologia di macchina con guida a terra e guida aerea ( BertUP 450 GA) che permette di ridurre al massimo gli ingombri, garantendo una flessibilità sempre maggiore rispetto alle passerelle fisse. Per gli interessati è possibile fornire, a servizio delle passerelle, una gru a braccio rotante, scorrevole per tutta la lunghezza del binario per la rimozione di componenti fino a 2 tons.", 
            description: "The mobile platforms are normally moving on rails that are installed on the depot floor. The worktop can be raised from a resting height (about 900 mm) up to a maximum height of 5200 mm (depending on the model). Bertolotti proposes an innovative guiding system thanks to which, using only a coloured line on the on the depot floor, the mobile platforms move parallel to the train, without rails. The advantages of the optical guiding mobile platforms are unbelievable. For customers who need a small footprint on the ground, we have developed a type of machine with walk-behind and aerial guiding (BertUP 450 GA) which allows to reduce the overall dimensions as much as possible, guaranteeing ever greater flexibility compared to fixed gangways. For those interested, it is possible to provide, together with working platforms, a rotating arm crane, sliding along the entire length of the track for the removal of components up to 2 tons.", 
            categoria: "Piattaforme per accesso al tetto del treno (Imperiale)",
            img: "/assets/img/passerelle_mobili.jpg",
            icon: "/assets/img/sollevatori.png"
        },
        {
            id: 9, 
            nome: "Binario apribile rialzato", 
            name: "Elevated rotating track section", 
            descrizione: "Questo sistema offre un modo semplice per lavorare sulla parte inferiore del materiale rotabile. Con una tavola di sollevamento è possibile rimuovere le sale montate e i componenti principali dal sottocassa del treno con grande efficienza.",
            description: "This system offers an easy way to work on the underside of rolling stock. With a lifting table it is possible to remove wheelsets and main components from the underbody of the train with great efficiency.",
            categoria: "Sistemi di movimentazione",
            img: "/assets/img/binario_apribile_2.jpg",
            icon: "/assets/img/passerelle_mobili.png"
        },
        {
            id: 10, 
            nome: "Piattaforme rotazione sale e carrelli",
            name: "Bogie adn wheelset turntable",
            descrizione: "Le piattaforme di rotazione sono il modo migliore per trasferire in modo rapido e sicuro carrelli e sale montate in officina. Le piattaforme di rotazione carrelli (da 6t a 25t) o assili (da 2t a 6t) sono forniti con diversi diametri e scartamenti in base al luogo di installazione. Possono essere azionati manualmente o motorizzati.", 
            description: "Bogie turntables are the best way to quickly and safely transfer bogies and wheelsets to the workshop. The Bogie turntables (from 6t to 25t) or wheelset turntables (from 2t to 6t) are supplied with different diameters and gauges according to the place of installation. They can be operated manually or motorized.", 
            categoria: "Sistemi di movimentazione",
            img: "/assets/img/piattaforme_rotazione.jpg",
            icon: "/assets/img/passerelle_mobili.png"
        },
        {
            id: 11, 
            nome: "Tavola trasporto carrelli",
            name: "Bogie transfer table",
            descrizione: "Questa piattaforma è del tipo idraulico a forbice e garantisce un modo semplice per rimuovere i carrelli e le attrezzature sottoscocca da un treno sollevato. E’ preferibilmente utilizzata con veicoli tramviari, in combinazione con sollevatori a colonna. La tavola ha una capacità di sollevamento di 6,5 tons. La piattaforma è dotata di batterie a bordo per lo spostamento sul binario di officina e di un arrotolatore per alimentare la pompa di sollevamento del pianto superiore una volta posizionato sotto il carrello da rimuovere. Le operazioni sono controllate con pulsantiera pensile o, in opzione, con radiocomando.", 
            description: "This platform is an hydraulic scissor type and provides an easy way to remove bogies and equipment from a raised train. It is preferably used with tram vehicles, in combination with lifting jacks. The table has a lifting capacity of 6.5 tons. The platform is equipped with on-board batteries for moving on the workshop track and a reel to power the pump to lift once it is positioned under the bogie to be removed. The operations are controlled with a pendant push-button or, optionally, with a radio remote control.", 
            categoria: "Sistemi di movimentazione",
            img: "/assets/img/tavola_trasporto_carrelli.jpg",
            icon: "/assets/img/passerelle_mobili.png"
        },
        {
            id: 12, 
            nome: "Falsi carrelli", 
            name: "Auxiliary bogies", 
            descrizione: "Carrelli ausiliari (chiamati anche “falsi” carrelli) hanno capacità da 10 a 25 tonnellate e sono composti da una struttura principale in acciaio con un supporto centrale per il vagone e quattro ruote. Possono essere azionati manualmente o motorizzati. Il supporto può essere con altezza fissa o altezza regolabile. Possono essere forniti modelli anche con sistema si sospensioni regolabili per garantire il superamento di ostacoli senza problemi di stabilità per la cassa trasportata. E’ sempre possibile progettare macchine diverse rispondenti alle esigenze di ciascun cliente.", 
            description: "Auxiliary bogies (also called “dummy” bogies) have capacities from 10 to 25 tons and consist of a main steel structure on 4 wheels with a central support for the coach. They can be operated manually or motorized. The support interface can be with fixed height or adjustable height. Auxiliary bogies can also be supplied with adjustable suspension systems to ensure the overcoming of obstacles without stability problems for the coach. It is always possible to design different machines to meet the needs of each customer.", 
            categoria: "Sistemi di movimentazione",
            img: "/assets/img/falsi_carrelli.jpg",
            icon: "/assets/img/passerelle_mobili.png"
        },
        {
            id: 13, 
            nome: "“Vario”", 
            name: "“Vario”", 
            descrizione: "“Vario” è un carrello universale progettato per la movimentazione di carichi pesanti. Grazie al suo speciale sistema di guida è in grado di eseguire ogni tipo di movimento possibili su un piano orizzontale. In effetti può muoversi in modalità retta, avanti e indietro, in diagonale, a 90 ° e ruotare su se stesso di 360°. È in grado di trasportare componenti o carrelli fino a 10 tonnellate di peso e dispone di un gruppo di sollevamento che consente di sollevare il carico a qualunque altezza fino a 1500 mm. E’ alimentato con batterie ad alta capacità e guidato da un telecomando che fornisce un alto livello di flessibilità. “Vario10” è la macchina ideale per ogni deposito in quanto riesce a superare le rotaie senza problemi e muoversi in ogni zona.", 
            description: "“Vario” is a universal vehicle designed for handling heavy loads. Thanks to its special guiding system it is able to perform any type of movement possible on a horizontal plane. In fact, it can move straight, back and forth, diagonally, at 90 ° and rotate on itself 360 °. It is capable of transporting components or bogies up to 10 tons and lifting at any height up to 1500 mm. It is powered by high-capacity batteries and guided by a remote control that provides a high level of flexibility. “Vario” is the perfect machine for every depot as it can pass the rails and move in any area.", 
            categoria: "Sistemi di movimentazione",
            img: "/assets/img/vario_1.jpg",
            icon: "/assets/img/passerelle_mobili.png"
        },
        {
            id: 14, 
            nome: "Trasbordatori", 
            name: "Traverser", 
            descrizione: "Bertolotti Rail è in grado di fornire diverse macchine con capacità di carico da 60 ton a 250 ton e lunghezza del ponte di carico da 20 m a 36 m o in base alle richieste del cliente. La macchina può essere installata con fossa o senza fossa, completamente automatica o con controllo semiautomatico. Ogni macchine è normalmente progettata seguendo le indicazioni del clienti, le caratteristiche dei treni e del deposito dove sarà installata.", 
            description: "Bertolotti Rail can supply various type of traversers with load capacities up to 250 tons and length of the loading bridge up to 40m or according to customer's request. The machine can be installed in pit or in square without pit and it can be fully automatic or with semi-automatic control. Each machine is normally designed following the customer's instructions, the characteristics of the trains and the depot where it will be installed.", 
            categoria: "Sistemi di movimentazione",
            img: "/assets/img/traverser_2.jpg",
            icon: "/assets/img/passerelle_mobili.png"
        },
        {
            id: 15, 
            nome: "Rinnovamento del deposito",
            name: "Renovation of depot",
            descrizione: "Bertolotti Rail in collaborazione con costruttori civili o in autonomia, propone degli studi dedicati volti al rinnovamento del deposito, non solo a livello di manutenzione ma anche a livello energetico e normativo. Bertolotti Rail può proporre in questo caso pacchetti di macchine di propria costruzione e integrare attrezzature di altri costruttori. Può fornire un pacchetto “chiavi in mano” che includa armamento, elettrificazione, impianti tecnologici, impianti fotovoltaici e riscaldamento. In caso di nuovo deposito, fornirà in collaborazione con i suoi partner anche il capannone, in carpenteria o calcestruzzo.", 
            description: "Bertolotti Rail in collaboration with civil contractors or independently, proposes solutions dedicated to the renovation of the depots, not only at the maintenance level but including energy solutions compliant with actual norms. In this case, Bertolotti Rail can propose packages of machines of its own construction and integrate equipment from other manufacturers like washing plants, underfloor wheel lathes, presses and minor equipment. Bertolotti Rail can provide a “turnkey” package that includes rails and infrastructure, electrification, technological systems, solar energy systems including heating devices. In case of new railway depot, in collaboration with its partners also the complete shed that can be realized in steel or concrete.", 
            categoria: "Sistemi di movimentazione",
            img: "/assets/img/depot_renovation.jpg",
            icon: "/assets/img/passerelle_mobili.png"
        },
        {
            id: 16, 
            nome: "Impianti rifornimento sabbia",
            name: "Sand refilling system",
            descrizione: "Gli impianti di rifornimento sabbia possono essere forniti per tutti i sistemi di trasporto per riempire di sabbia le sabbiere dei rotabili. Possono essere forniti come installazione fissa o come unità mobile, nel caso siano completi di silo di stoccaggio sabbia o altri tipi di unità di riempimento della sabbia. La soluzione fissa è essenzialmente composta da un silo di stoccaggio, propulsore e serbatoi locali, ciascuno completo di uno o due pistole di riempimento. L'impianto è completamente automatico e dotato di compressore e unità di filtrazione delle polveri centralizzata o localizzata. Le tubazioni di trasporto della sabbia, sono in acciaio inox, con installazione aerea o in cunicolo a livello del pavimento. L'unità mobile è indipendente, dotata di serbatoio di stoccaggio con capaità da 200Lt a 500Lt e montata su un veicolo elettrico o semplicemente su un telaio mobile su ruote. Presenta un compressore e un sistema di filtraggio della polvere a bordo.",
            description: "The sanding systems can be supplied for all transportation systems to refill the sand into the sand boxes of the rolling stocks. They can be provided as a fixed installation or as mobile unit, in case complete with sand storage silo or other types of sand filling units. The fixed solution is essentially composed of a sand storage silo, a propeller and local sand tanks, each one complete with one or two dispensing guns. The sanding system is completely automatic and equipped with a compressor and centralized dust filtering unit. Sand transfer piping can be fitted to the roof or inside a trench at floor level. The mobile unit is independent indeed, equipped with a sand tank (200Lt, 350Lt or 500Lt available capacity), and fitted on an electric vehicle or just a frame movable with forklift. It features a compressor and dust filtering system on-board.", 
            categoria: "Sistemi di rifornimento sabbia",
            img: "/assets/img/impianti_sabbia.jpg",
            icon: "/assets/img/impianti_sabbia.png"
        },
    ];

    const [categoriaSelezionata, setCategoriaSelezionata] = useState(null);
    const [espansi, setEspansi] = useState([]);
    
    const filtraPerCategoria = (categoria) => {
        // Controlla se la categoria selezionata è già quella attiva
        if (categoriaSelezionata === categoria) {
          // Se lo è, rimuove i filtri resettando categoriaSelezionata a null
          setCategoriaSelezionata(null);
        } else {
          // Altrimenti, imposta categoriaSelezionata alla nuova categoria selezionata
          setCategoriaSelezionata(categoria);
        }
    };

    const prodottiFiltrati = categoriaSelezionata ? prodottiIniziali.filter(prodotto => prodotto.categoria === categoriaSelezionata) : prodottiIniziali;

    const toggleEspansione = (id) => {
        setEspansi(espansi.includes(id) ? espansi.filter(eId => eId !== id) : [...espansi, id]);
    };

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 8,
        slidesToScroll: 1,
        centerMode: true,
        autoplay: true,
        speed: 3000,
        cssEase: "linear",
        autoPlaySpeed: 0,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
            }
        ]
    };

    return (
        <>
            {
            language === 'en' ? (
                <Helmet>
                <title>Bertolotti Rail</title>
                <meta name="description" content="Specialized in the constructin of systems for the maintenance of railway rolling stock (subway and trams)." />    
                </Helmet>
                ) : (
                <Helmet>
                <title>Bertolotti Rail</title>
                <meta name="description" content="Specializzata nella costruzione di sistemi per la manutenzione del materiale rotabile ferroviario (metropolitane e tram)." />    
                </Helmet>
                )
            }
            <NavbarComponent />
            <div style={{ height: '100vh', backgroundImage: 'url(/assets/img/bertolottirail_header.png)', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <div style={{ textAlign: 'center' }} className="mx-auto d-flex align-items-center h-100">
                    <div className="container w-100">
                        <h1 className="h1-resp text-white">Bertolotti Rail</h1>
                        <h3 className="h3-resp text-white">
                        {
                            language === 'en' ? `Specialized in the constructin of systems for the maintenance of railway rolling stock (subway and trams)`
                            : `Specializzata nella costruzione di sistemi per la manutenzione del materiale rotabile ferroviario (metropolitane e tram)`
                        }
                        </h3>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#F3F6F8' }} className="py-3" >
                <h2 className="text-center py-3 mt-3" style={{color: '#262A30', fontSize:'36px'}}>
                {
                    language === 'en' ? `Trusted by`
                    : `Scelti da`
                }
                </h2>
                <Slider {...settings} className="py-4" >
                    <img src="/assets/img/sbbcffffs_logo.png" alt="" ></img>
                    <img src="/assets/img/brussels_logo.png" alt="" ></img>
                    <img src="/assets/img/trenitalia_logo.png" alt="" ></img>
                    <img src="/assets/img/ratp_logo.png" alt="" ></img>
                    <img src="/assets/img/networkrail_logo.png" alt="" ></img>
                    <img src="/assets/img/trenord_logo.png" alt="" ></img>
                    <img src="/assets/img/ntv_logo.png" alt="" ></img>
                    <img src="/assets/img/atm_logo.png" alt="" ></img>
                </Slider>
            </div>
            <div className="py-5 mt-5">
                <div className="container my-5">
                    <div className="row g-3 g-lg-5">
                        <div className="col-md-6 d-flex align-items-center">
                            <img src='/assets/img/bertolottirail_train.png' alt="bertolotti service" className="img-fluid"></img>
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                            <div>
                                <h2 className="py-2" style={{fontSize: '30px'}}>
                                {
                                    language === 'en' ? `Solutions for set-up of rolling stock maintenance depots`
                                    : `Soluzioni per set-up di depositi ferroviari e manutenzione del materiale rotabile`
                                }

                                </h2>
                                <br />
                                {
                                language === 'en' ? (
                                    <p className="pb-3 pb-md-0">
                                        Bertolotti Rail specializes in the railway sector and deals with the provisioning of systems for efficient handling and maintenance of railway rolling stock.<br />
                                        A mix of B2B and B2B2B business model.<br />
                                        As of today, Bertolotti Rail is a partner of a few transport companies (railway and metropolitan), both domestic and foreign and mostly public, and also a partner of leading engineering companies for the design of railway depots, providing its own experience and assistance during the preparation of tender  specifications or feasibility studies.<br />
                                        Its business model revolves around both direct B2B contracting with public or private  transportation operators, and B2B2B contracting, where general contractors secure contracts  through public tenders and then subcontract logistic facilities design and execution to Bertolotti Rail.
                                    </p>
                                ) : (
                                    <p className="pb-3 pb-md-0">
                                        Bertolotti Rail è specializzata nel settore ferroviario e si occupa della fornitura di sistemi per la movimentazione efficiente, attrezzature di deposito standard per la manutenzione del materiale rotabile ferroviario.<br />
                                        Un modello di business misto tra B2B e B2B2B.<br />
                                        Ad oggi, Bertolotti Rail è partner di alcune aziende di trasporto (ferroviario e metropolitano), sia nazionali che estere e per lo più pubbliche e partner di primarie società di ingegneria per la progettazione di depositi ferroviari, mettendo a disposizione la propria esperienza e la propria assistenza durante la preparazione dei capitolati di gara o degli studi di fattibilità.<br />
                                        Il suo modello di business si basa sia sulla contrattazione diretta B2B con operatori di trasporto pubblici o privati, sia sulla contrattazione B2B2B, in cui i general contractor si aggiudicano i contratti attraverso gare pubbliche e poi subappaltano a Bertolotti Rail la progettazione e l'esecuzione delle strutture logistiche.
                                    </p>
                                )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*<div className="row gx-0">  
                <div className="col-md-6 d-flex align-items-center" style={{backgroundImage:'url(/assets/img/bertolottirail_liftingsolutions.png)', backgroundPosition:'center', backgroundRepeat:'no-repeat', height:'600px'}}>
                    <div className="container">
                        <div className="text-center w-100">
                            <label className="text-white px-2 py-1" style={{backgroundColor:'#C01725', borderRadius:'60px', fontSize:'12px'}}>FOCUS ON</label>
                            <h2 className="text-white pt-3 pb-2" style={{fontSize: '30px'}}>
                                {
                                    language === 'en' ? `Lifting Solutions`
                                    : `Soluzioni sollevamento`
                                }
                            </h2>
                            <div className="d-flex justify-content-center text-start">
                            {
                                language === 'en' ? (
                                    <p style={{color:'#F3F6F8'}}>
                                        <ul>
                                            <li>Bogie lowering systems (or bogie drop) for component replacement</li>
                                            <li>Bogie frame rotating devices for efficient inspections</li>
                                            <li>Axle drop systems for easy axle replacement</li>
                                            <li>Standard andtailor made lifting jacks for versatile maintenance operations</li>
                                        </ul>
                                    </p>
                                ) : (
                                    <p style={{color:'#F3F6F8'}}>
                                        <ul>
                                            <li>Sistemi di montaggio/smontaggio del carrello (calacarrelli), assili e altri componenti</li>
                                            <li>Dispositivi di rotazione del telaio del carrello per ispezioni</li>
                                            <li>Sistemi cala-assi per una facile sostituzione degli assili</li>
                                            <li>Colonne di sollevamento (cavalletti standard e personalizzabili per operazioni<br />di manutenzione versatili)</li>
                                        </ul>
                                    </p>
                                )
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 d-flex align-items-center" style={{backgroundImage:'url(/assets/img/bertolottirail_platformsroof.png)', backgroundPosition:'center', backgroundRepeat:'no-repeat', height:'600px'}}>
                    <div className="container">
                        <div className="text-center w-100">
                            <label className="text-white px-2 py-1" style={{backgroundColor:'#C01725', borderRadius:'60px', fontSize:'12px'}}>FOCUS ON</label>
                            <h2 className="text-white pt-3 pb-2" style={{fontSize: '30px'}} 
                                dangerouslySetInnerHTML={{ __html: language === 'en' ? `Platforms for roof access`
                                    : `Piattaforme per accesso al tetto del treno (Imperiale)`}}
                                />

                            <div className="d-flex justify-content-center text-start">
                            {
                                language === 'en' ? (
                                    <p style={{color:'#F3F6F8'}}>
                                        <ul>
                                            <li>Fixed working platforms are permanent structuresto the structure of the depot to<br />provide stable and customized access to the roof for maintenance work</li>
                                            <li>Mobile working platforms are movable platforms that can be positioned alongside<br />the train, offering flexible and versatile roof access in various locations</li>
                                        </ul>
                                    </p>
                                ) : (
                                    <p style={{color:'#F3F6F8'}}>
                                        <ul>
                                            <li>Le piattaforme di lavoro fisse sono strutture permanenti che forniscono un accesso<br />stabile, sicuro e personalizzato al tetto per i lavori di manutenzione</li>
                                            <li>Le piattaforme di lavoro mobili sono piattaforme che possono essere posizionate a<br />fianco del treno, offrendo un accesso al tetto flessibile e versatile in varie posizioni</li>
                                        </ul>
                                    </p>
                                )
                            }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row gx-0">  
                <div className="col-md-6 d-flex align-items-center" style={{backgroundImage:'url(/assets/img/bertolottirail_handlingsystems.png)', backgroundPosition:'center', backgroundRepeat:'no-repeat', height:'600px'}}>
                    <div className="container">
                        <div className="text-center w-100">
                            <label className="text-white px-2 py-1" style={{backgroundColor:'#C01725', borderRadius:'60px', fontSize:'12px'}}>FOCUS ON</label>
                            <h2 className="text-white pt-3 pb-2" style={{fontSize: '30px'}}>
                                {
                                    language === 'en' ? 'Handling Systems'
                                    : "Sistemi di movimentazione"
                                }
                            </h2>
                            <div className="d-flex justify-content-center text-start">
                            {
                                language === 'en' ? (
                                    <p style={{color:'#F3F6F8'}}>
                                        <ul>
                                            <li>Elevated rotating track sections for easy access to the lower part of the coach</li>
                                            <li>Bogie transfer tables for easy removal of bogies</li>
                                            <li>Auxiliary bogies with adjustable height of the supporting interface</li>
                                            <li>Special vehicles with various movements and heavy load handling</li>
                                            <li>Traversers with high load capacities for efficient depot operations,<br />and comprehensive depot renovation solutions integrating various<br />equipment and infrastructure components;</li>
                                        </ul>
                                    </p>
                                ) : (
                                    <p style={{color:'#F3F6F8'}}>
                                        <ul>
                                            <li>Sezioni di binario rialzate apribili  per un facile accesso alla parte inferiore del treno</li>
                                            <li>Tavoli di trasferimento dei carrelli per una comoda rimozione degli stessi</li>
                                            <li>Carrelli ausiliari con sistemi di sospensione regolabili</li>
                                            <li>Veicoli versatili in grado di effettuare diversi movimenti e movimentare carichi pesanti</li>
                                            <li>Trasbordatori con elevate capacità di carico per operazioni di deposito efficienti e<br />soluzioni complete per la ristrutturazione dei depositi che integrano varie<br />attrezzature e componenti infrastrutturali</li>
                                        </ul>
                                    </p>
                                )
                            }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 d-flex align-items-center" style={{backgroundImage:'url(/assets/img/bertolottirail_sandingsystems.jpg)', backgroundPosition:'center', backgroundRepeat:'no-repeat', height:'600px'}}>
                    <div className="container">
                        <div className="text-center w-100">
                            <label className="text-white px-2 py-1" style={{backgroundColor:'#C01725', borderRadius:'60px', fontSize:'12px'}}>FOCUS ON</label>
                            <h2 className="text-white pt-3 pb-2" style={{fontSize: '30px'}}>
                                {
                                    language === 'en' ? `Sanding Systems`
                                    : `Sistemi di rifornimento sabbia`
                                }
                            </h2>
                            <div className="d-flex justify-content-center text-start">
                            {
                                language === 'en' ? (
                                    <p style={{color:'#F3F6F8'}}>
                                        <ul>
                                            <li>Fixed or mobile units used to refill sand into sandboxes of rolling stock.<br />Sand boxes are installed on the bogies of the trains to improve traction and breaking<br />performance by providing controlled sand release onto the tracks,<br />enhancing friction with bad weather conditions.</li>
                                        </ul>
                                    </p>
                                ) : (
                                    <p style={{color:'#F3F6F8'}}>
                                        <ul>
                                            <li>Impianti fissi o unità mobili utilizzate per riempire di sabbia le sabbiere del materiale rotabile.<br />I sistemi di iniezione sabbia sono installati sui treni per migliorare le prestazioni di trazione<br />e di frenatura grazie al rilascio controllato di sabbia sui binari,<br />migliorando l'aderenza in condizioni climatiche avverse.</li>
                                        </ul>
                                    </p>
                                )
                            }
                            </div>
                            <br /><br /><br />
                        </div>
                    </div>
                </div>
            </div>*/}

            <div className="bg-light py-5">
                <h2 className="text-center m-0">{language === 'en' ? ('Solutions') : ('Soluzioni')}</h2>
            </div>

            <CategorieSelect
                categorie={categorie}
                categoriaSelezionata={categoriaSelezionata}
                onCategoriaClick={filtraPerCategoria}
                lang={language}
            />

            <div className="container">
                <div className="row my-5">
                {/* Render dei prodotti filtrati */}
                {prodottiFiltrati.map(prodotto => (
                    <div key={prodotto.id} className="col-md-4 mb-4">
                    <ProdottoCard 
                        prodotto={prodotto} 
                        isExpanded={espansi.includes(prodotto.id)} 
                        onToggle={toggleEspansione}
                        lang={language}
                    />
                    </div>
                ))}
                </div>
            </div>

            <Footer />
        </>
    )
}
import { useLanguageContext } from "../../Context/LanguageContext"

export default function HeaderSliderItem({ imgSrc, title, desc, titleEN, descEN }) {
    const { language } = useLanguageContext()
    
    return (
        <div className="height-fill img-fluid" style={{ backgroundImage: `url(${imgSrc})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', width: '100vw' }}>
            <div style={{ backgroundColor: 'rgba(34, 34, 34, .6)' }} className="h-100 w-100 d-flex align-items-center">
                <div className="w-100 text-center">
                    <h1 className="mb-3 text-white py-3 py-md-0">{language === 'it' ? title : titleEN}</h1>
                    <h3 className="mb-5 text-white py-3 py-md-0">{language === 'it' ? desc : descEN}</h3>
                </div>
            </div>
        </div>
    )
}
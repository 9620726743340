import React from "react";
import { LateralMenuProps } from "../utility/interface";
import { BsArrowRightShort } from "react-icons/bs";

export default function LateralMenu({ data, setTab }: LateralMenuProps) {

    const stampaTitleLateralMenu = data.map((p, index) => {

        const stampaLateralMenu = p.list.map((d, index) => {
            return (
                <>
                    <div id="lateralMenu" onClick={() => { setTab(d.nome) }} style={{ borderBottom: '2px solid #DBDBDB' }} className="d-flex flex-row align-items-center mt-2">
                        <div className="w-75 mb-2">
                            <label style={{ fontSize: '12px' }}>
                                {d.nome}
                            </label>
                        </div>
                        <div className="w-25 mb-2 d-flex justify-content-end">
                            <BsArrowRightShort className="iconArrow" size={40} style={{ color: '#C00322' }} />
                        </div>
                    </div>
                </>
            )
        })

        return (
            <>
                <div className="mt-5">
                    <label style={{ fontSize: '28px' }} dangerouslySetInnerHTML={{ __html: p.title }} />
                    <div style={{ height: '4px', width: '50px', background: '#C00322' }} />
                </div>
                <div className="mt-3">
                    {stampaLateralMenu}
                </div>
            </>
        )
    })

    return (
        <>
            <div>
                {stampaTitleLateralMenu}
            </div>
        </>
    )
}
import React, { useState } from 'react';
import NavbarComponent from '../components/NavbarComponent.jsx';
import Footer from '../components/Footer.jsx';
import { Container, Form, Button, Row, Col } from 'react-bootstrap';
import Helmet from 'react-helmet';
import { useLanguageContext } from '../Context/LanguageContext.jsx';
import emailjs from 'emailjs-com';

function SegnalazioniSA8000() {
  const { language, cookieConsent } = useLanguageContext();
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
    accepted: false,
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.name || !formData.email || !formData.subject || !formData.message || !formData.accepted) {
      alert('Tutti i campi sono obbligatori.');
      return;
    }

    emailjs.sendForm(
      'service_18mqw25', // Replace with your EmailJS service ID
      'template_cj910bz', // Replace with your EmailJS template ID
      e.target,
      'uZs9FI9IQQZIHr5TG' // Replace with your EmailJS user ID
    ).then((result) => {
      alert('Email inviata con successo!');
    }, (error) => {
      alert('Errore nell\'invio dell\'email: ' + error.text);
    });

    setFormData({
      name: '',
      email: '',
      subject: '',
      message: '',
      accepted: false,
    });
  };

  return (
    <>
      <Helmet>
        <title>Certificazioni - Bertolotti S.p.A.</title>
      </Helmet>
      <NavbarComponent />
      <Container className='my-5 py-5'>
        <div>
        </div>
        <Row className="justify-content-center">
          <Col xs={12} md={6}>
          <div className='py-4'>
            <h6>Per fornire suggerimenti o reclami puoi scrivere direttamente a:</h6>
            <h6><a href='mailto:segnalazioni@bertolottispa.it'>segnalazioni@bertolottispa.it</a></h6>
            <h6>oppure compilare il modulo che trovi nella pagina.</h6>
            <h6>La Società valuterà il tuo suggerimento/reclamo e attuerà le azioni ritenute necessarie.</h6>
          </div>
          <Form onSubmit={handleSubmit} className="p-4 shadow-sm bg-white rounded">
              <Form.Group controlId="formName">
                <Form.Label>Nome e Cognome</Form.Label>
                <Form.Control
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formSubject">
                <Form.Label>Oggetto</Form.Label>
                <Form.Control
                  type="text"
                  name="subject"
                  value={formData.subject}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formMessage">
                <Form.Label>Messaggio</Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Form.Group controlId="formAccepted" className='py-3'>
                <Form.Check
                  type="checkbox"
                  label={
                    <>
                      Ho letto e accetto
                      <a href="/documents/privacy.pdf" target="_blank" rel="noopener noreferrer">
                        {' l\'informativa privacy '}
                      </a>
                      di questo sito
                    </>
                  }
                  name="accepted"
                  checked={formData.accepted}
                  onChange={handleChange}
                  required
                />
              </Form.Group>

              <Button variant="primary" type="submit" className="w-100">
                Invia messaggio
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
      <Footer />
    </>
  );
}

export default SegnalazioniSA8000;

import Footer from "../components/Footer";
import NavbarComponent from "../components/NavbarComponent";

import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLanguageContext } from "../Context/LanguageContext";
import React, { useState, useEffect } from 'react';
import CategorieSelect from "../components/CategorieSelect";
import ProdottoCard from "../components/ProdottoCard";
import { Helmet } from 'react-helmet';

import ReactGA from "react-ga4";

export default function BertolottiIndustries() {

    const { language, cookieConsent } = useLanguageContext(); 

    useEffect(() => {
        if(cookieConsent === 'false' || !cookieConsent) return
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, [cookieConsent]);

    const categorie = [
        { 
            id: 'CRA', 
            nome: 'Cranes Automation Warehouse (CRA)', 
            name: 'Cranes Automation Warehouse (CRA)', 
            descrizione: 'prodotti e componenti dedicati al Pick up, al trasferimento e allo stoccaggio per la movimentazione aerea (carroponte automatici, gru per bobine, sistemi di carico e scarico e magazzini automatici per bobine).', 
            description: 'dedicated Pick up, transfer & stock products and parts for overhead handling (automatic overhead cranes, coil cranes, loading and unloading systems, and automatic coil warehouses).', 
            icon: '/assets/img/icona_gancio.png' 
        },
        { 
            id: 'AGP', 
            nome: 'Automated Grounded Products (AGP)', 
            name: 'Automated Grounded Products (AGP)', 
            descrizione: 'soluzioni su misura per la movimentazione e l\'imballaggio di parti pesanti nel settore dell\'acciaio e dell\'alluminio e in altri settori (AGV, sistemi di trasferimento su rotaia, linee di imballaggio per bobine e laminatoi).', 
            description: 'tailor made solutions for moving and packing heavy parts in the steel and other business sectors (AGV, rail transfer systems, coil packing lines and rolling mills).', 
            icon: '/assets/img/icona_muletto.png' 
        },
        { 
            id: 'Bulk Material Handling', 
            nome: 'Movimentazione materiali sfusi', 
            name: 'Bulk Material Handling', 
            descrizione: 'trasportatori nastri , silos, soluzioni su misura per il dosaggio e la movimentazione di materiali sfusi (minerale di ferro, carbone, coke, ecc.).',
            description: 'conveyor belts, silos, stacking and reclaiming tailor-made solutions to dose and move bulk materials (iron ore, coal, coke etc.).',
            icon: '/assets/img/icona_nastro.png' 
        },
        { 
            id: 'Aeronautica', 
            nome: 'Segmento aeronautico', 
            name: 'Aeronautic Segment', 
            descrizione: 'celle di movimentazione e produzione.', 
            description: 'material handling & manufacturing cells.', 
            icon: '/assets/img/icona_aereo.png' 
        },
    ];    

    const prodottiIniziali = [
        {
            id: 1, 
            nome: "Automated Guided Vehicles (AGV)", 
            name: "Automated Guided Vehicles (AGV)", 
            descrizione: "travel on polyurethane wheels, without tracks, and can follow structured trajectories. Their deployments enables to optimize the layouts and facilitates the coexistence with persons and goods transport vehicles. This coexistence implies safety issues to which Bertolotti has found solutions by adopting safety-class laser scanners and eventual redundant devices and solutions in order to totally satisfy the essential requisites of machinery directive and health&safety regulations more in general.", 
            description: "travel on polyurethane wheels, without tracks, and can follow structured trajectories. Their deployments enables to optimize the layouts and facilitates the coexistence with persons and goods transport vehicles. This coexistence implies safety issues to which Bertolotti has found solutions by adopting safety-class laser scanners and eventual redundant devices and solutions in order to totally satisfy the essential requisites of machinery directive and health&safety regulations more in general.", 
            categoria: "CRA",
            img: "/assets/img/automated_guided_vehichles.jpg",
            icon: "/assets/img/icona_gancio.png"
        },
        {
            id: 2,
            nome: "Rail bound high speed transfer system", 
            name: "Rail bound high speed transfer system", 
            descrizione: "BERTOLOTTI Industries creates a range of highly developed coil movement systems comprising walking beam and chain conveyors as well as sets of self-guided cars on rail-tracks. Rail-cars systems in particular satisfy the requirements of flexible complex logistic systems demanding inter-exchanges and cross movements within the plant. The systems are modular and every individual car moves autonomously on predefined routes, accommodating layout constraints respecting synchronisations on the basis of a production program and according to the conditions and relevant obstacles along the route. The overall safety concept includes the appraisal of all dangerous conditions and subsequent identification of equipment, sensors, strategies and procedures to offset all existing critical factors and ensure 100% safe working conditions.", 
            description: "BERTOLOTTI Industries creates a range of highly developed coil movement systems comprising walking beam and chain conveyors as well as sets of self-guided cars on rail-tracks. Rail-cars systems in particular satisfy the requirements of flexible complex logistic systems demanding inter-exchanges and cross movements within the plant. The systems are modular and every individual car moves autonomously on predefined routes, accommodating layout constraints respecting synchronisations on the basis of a production program and according to the conditions and relevant obstacles along the route. The overall safety concept includes the appraisal of all dangerous conditions and subsequent identification of equipment, sensors, strategies and procedures to offset all existing critical factors and ensure 100% safe working conditions.", 
            categoria: "CRA",
            img: "/assets/img/railbound.jpg",
            icon: "/assets/img/icona_gancio.png"
        },
        {
            id: 3, 
            nome: "Coil Packing Lines",
            name: "Coil Packing Lines",
            descrizione: "Each packaging line is tailor made for each individual project (type of packaging, material flow, lay-out constraints) and individual customer’s needs. Packaging facilities are included in the overall logistics concept to increase the productivity and efficiency of the packaging department; optimally organizing material flow and speed-up of packing tasks are key objectives.", 
            description: "Each packaging line is tailor made for each individual project (type of packaging, material flow, lay-out constraints) and individual customer’s needs. Packaging facilities are included in the overall logistics concept to increase the productivity and efficiency of the packaging department; optimally organizing material flow and speed-up of packing tasks are key objectives.", 
            categoria: "CRA",
            img: "/assets/img/coil_packing_line.jpg",
            icon: "/assets/img/icona_gancio.png"
        },
        {
            id: 4, 
            nome: "Roll shop Equipment", 
            name: "Roll shop Equipment", 
            descrizione: "Bertolotti can design and supply de-chockers for work-rolls and backup rolls to relieve the roll shop from heavy-duty and hazardous activities, facilitate and make operations safe, with an outstanding improvement of production rate. The scope includes chocks and rolls handling devices (tongs, transfer cars, tilters, lifting beams etc…) and chock maintenance tools as well as work-rolls removing rigs, jib cranes, locking nut removal devices. The above set of devices allows to do away with hazardous operations with cranes and steel ropes enhancing safety while maximising throughput.", 
            description: "Bertolotti can design and supply de-chockers for work-rolls and backup rolls to relieve the roll shop from heavy-duty and hazardous activities, facilitate and make operations safe, with an outstanding improvement of production rate. The scope includes chocks and rolls handling devices (tongs, transfer cars, tilters, lifting beams etc…) and chock maintenance tools as well as work-rolls removing rigs, jib cranes, locking nut removal devices. The above set of devices allows to do away with hazardous operations with cranes and steel ropes enhancing safety while maximising throughput.", 
            categoria: "CRA",
            img: "/assets/img/roll_shop.jpg",
            icon: "/assets/img/icona_gancio.png"
        },
        {
            id: 5, 
            nome: "Other Conveying systems", 
            name: "Other Conveying systems", 
            descrizione: "When new ideas & special system are requested, Bertolotti can provide tailor made solutions to satisfy customer needs", 
            description: "When new ideas & special system are requested, Bertolotti can provide tailor made solutions to satisfy customer needs", 
            categoria: "CRA",
            img: "/assets/img/other_conveying_systems.jpg",
            icon: "/assets/img/icona_gancio.png"
        },
        {
            id: 6, 
            nome: "Coil Overhead Handling Cranes", 
            name: "Coil Overhead Handling Cranes", 
            descrizione: "Dedicated application and solution for heavy Coil Over Head handling", 
            description: "Dedicated application and solution for heavy Coil Over Head handling", 
            categoria: "AGP",
            img: "/assets/img/coil_overhead.jpg",
            icon: "/assets/img/icona_muletto.png"
        },
        {
            id: 7, 
            nome: "Special Cranes, Manipulators, Casting systems", 
            name: "Special Cranes, Manipulators, Casting systems", 
            descrizione: "Large range of dedicated solutions for heavy parts overhead handling in hazardous environmental. Our advanced handling and lifting equipment, technologies can improve the safety and productivity of operations across every area of your plant.", 
            description: "Large range of dedicated solutions for heavy parts overhead handling in hazardous environmental. Our advanced handling and lifting equipment, technologies can improve the safety and productivity of operations across every area of your plant.", 
            categoria: "AGP",
            img: "/assets/img/special_cranes.jpg",
            icon: "/assets/img/icona_muletto.png"
        },
        {
            id: 8, 
            nome: "Automatic Warehouses", 
            name: "Automatic Warehouses", 
            descrizione: "Goods handling needs absolute precision and smooth operations; overhead travelling cranes move along and reach their position with absolute precision and speed control thanks to a special control arrangement that has been created on purpose with decisions made in real time; our control devices use safety certified PLC and multiprocessor computer with fast and safe memorization peripherals. All logistical tasks are executed in automatic mode but manual operation is always possible whilst recording all manual movements.", 
            description: "Goods handling needs absolute precision and smooth operations; overhead travelling cranes move along and reach their position with absolute precision and speed control thanks to a special control arrangement that has been created on purpose with decisions made in real time; our control devices use safety certified PLC and multiprocessor computer with fast and safe memorization peripherals. All logistical tasks are executed in automatic mode but manual operation is always possible whilst recording all manual movements.", 
            categoria: "AGP",
            img: "/assets/img/automatic_warehouses.jpg",
            icon: "/assets/img/icona_muletto.png"
        },
        {
            id: 9, 
            nome: "Wagon/Truck Loading/Unloading systems", 
            name: "Wagon/Truck Loading/Unloading systems", 
            descrizione: "The Crane management system can load and unload Train Wagons in semi-automatic and automatic mode. The Crane management system can load and unload Trucks in semi-automatic mode.",
            description: "The Crane management system can load and unload Train Wagons in semi-automatic and automatic mode. The Crane management system can load and unload Trucks in semi-automatic mode.",
            categoria: "AGP",
            img: "/assets/img/wagon_truck_loading_unloading_systems.jpg",
            icon: "/assets/img/icona_muletto.png"
        },
        {
            id: 10, 
            nome: "Bulk Material Logistic", 
            name: "Bulk Material Logistic", 
            descrizione: "Complete Fe-alloys and additive charging systems; weighing and dosing solutions; stackers and reclaimers; ship loading and unloading systems. Conveying and handling equipment: belt conveyors; bucket and skip elevators; loading equipment; apron conveyors.", 
            description: "Complete Fe-alloys and additive charging systems; weighing and dosing solutions; stackers and reclaimers; ship loading and unloading systems. Conveying and handling equipment: belt conveyors; bucket and skip elevators; loading equipment; apron conveyors.", 
            categoria: "Bulk Material Handling",
            img: "/assets/img/bulk_material_logistic.jpg",
            icon: "/assets/img/icona_nastro.png"
        },
        {
            id: 11, 
            nome: "Power Plants ports and bulk terminals", 
            name: "Power Plants ports and bulk terminals", 
            descrizione: "All equipment needed for coal handling from the receiving area to the boiler house including: ship unloading facilities, belt conveyors, mobile hopper systems and associated extraction devices, stackers, apron conveyors, excavators and mobile transfer conveyors, reclaimers, screens, feeders.", 
            description: "All equipment needed for coal handling from the receiving area to the boiler house including: ship unloading facilities, belt conveyors, mobile hopper systems and associated extraction devices, stackers, apron conveyors, excavators and mobile transfer conveyors, reclaimers, screens, feeders.", 
            categoria: "Bulk Material Handling",
            img: "/assets/img/power_plant_ports_bulk_terminals.jpg",
            icon: "/assets/img/icona_nastro.png"            
        },
        {
            id: 12, 
            nome: "Steel Plants", 
            name: "Steel Plants", 
            descrizione: "Complete Fe-alloy and additive charging systems featuring: truck or railcar unloading stations; elevators (belt/bucket/skip type); belt conveyor systems; (reversible belts, shuttle conveyors, slewing belts); silo batteries and hoppers; weighing systems and feeders for dosing and recipe preparation; recipe gathering and unloading equipment. Powder storage, pneumatic conveying and dosing. Iron ore and coke handling, conveying and storage equipment: coke cooling cars; tripper cars; belt systems; hopper systems; weighing and dosing solutions; screening.", 
            description: "Complete Fe-alloy and additive charging systems featuring: truck or railcar unloading stations; elevators (belt/bucket/skip type); belt conveyor systems; (reversible belts, shuttle conveyors, slewing belts); silo batteries and hoppers; weighing systems and feeders for dosing and recipe preparation; recipe gathering and unloading equipment. Powder storage, pneumatic conveying and dosing. Iron ore and coke handling, conveying and storage equipment: coke cooling cars; tripper cars; belt systems; hopper systems; weighing and dosing solutions; screening.", 
            categoria: "Bulk Material Handling",
            img: "/assets/img/steel_plants.jpg",
            icon: "/assets/img/icona_nastro.png"
        },
        {
            id: 13, 
            nome: "Assembly Platforms", 
            name: "Assembly Platforms", 
            descrizione: "Equipment to support the assembly operations ensuring a safe, ergonomic, and efficient working environment to the operators. Sound working areas at proper and adjustable height, comfortable access stairs and platforms, lighting, sliding floors, tools, utilities and personnel safety devices are factored-in to deliver a complete efficient working cell.", 
            description: "Equipment to support the assembly operations ensuring a safe, ergonomic, and efficient working environment to the operators. Sound working areas at proper and adjustable height, comfortable access stairs and platforms, lighting, sliding floors, tools, utilities and personnel safety devices are factored-in to deliver a complete efficient working cell.", 
            categoria: "Aeronautica",
            img: "/assets/img/assembly_platforms.jpg",
            icon: "/assets/img/icona_aereo.png"
        },
        {
            id: 14, 
            nome: "Manufacturing cells", 
            name: "Manufacturing cells", 
            descrizione: "Complete cells to execute specific manufacturing sequences. Facilities designed & built for the manufacturing cycle of civil and military aircrafts. Engineering skills geared to deliver precise, safe, ergonomic, highest-stringent quality assurance requirements. Scope includes mechanics, electrics and automation as well as all related tools and utilities such as air treatment and conditioning (including white-chamber arrangement), vacuum system for suction of chips, dust and steam, compressed-air, lighting, IT networking.", 
            description: "Complete cells to execute specific manufacturing sequences. Facilities designed & built for the manufacturing cycle of civil and military aircrafts. Engineering skills geared to deliver precise, safe, ergonomic, highest-stringent quality assurance requirements. Scope includes mechanics, electrics and automation as well as all related tools and utilities such as air treatment and conditioning (including white-chamber arrangement), vacuum system for suction of chips, dust and steam, compressed-air, lighting, IT networking.", 
            categoria: "Aeronautica",
            img: "/assets/img/manufacturing_cells.jpg",
            icon: "/assets/img/icona_aereo.png"
        },
        {
            id: 15, 
            nome: "Handling Systems", 
            name: "Handling Systems", 
            descrizione: "Integrated solutions to move and handle parts, fuselages or complete aircrafts to assist the manufacturing and testing processes between the various manufacturing and assembly stations. The scope includes AGVs, Monorails, Special cranes, Manipulators.", 
            description: "Integrated solutions to move and handle parts, fuselages or complete aircrafts to assist the manufacturing and testing processes between the various manufacturing and assembly stations. The scope includes AGVs, Monorails, Special cranes, Manipulators.", 
            categoria: "Aeronautica",
            img: "/assets/img/handling_systems.jpg",
            icon: "/assets/img/icona_aereo.png"
        },
    ];

    const [categoriaSelezionata, setCategoriaSelezionata] = useState(null);
    const [espansi, setEspansi] = useState([]);
    
    const filtraPerCategoria = (categoria) => {
        // Controlla se la categoria selezionata è già quella attiva
        if (categoriaSelezionata === categoria) {
          // Se lo è, rimuove i filtri resettando categoriaSelezionata a null
          setCategoriaSelezionata(null);
        } else {
          // Altrimenti, imposta categoriaSelezionata alla nuova categoria selezionata
          setCategoriaSelezionata(categoria);
        }
    };

    const prodottiFiltrati = categoriaSelezionata ? prodottiIniziali.filter(prodotto => prodotto.categoria === categoriaSelezionata) : prodottiIniziali;

    const toggleEspansione = (id) => {
        setEspansi(espansi.includes(id) ? espansi.filter(eId => eId !== id) : [...espansi, id]);
    };

    const settings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 8,
        slidesToScroll: 1,
        centerMode: true,
        autoplay: true,
        speed: 3000,
        cssEase: "linear",
        autoPlaySpeed: 0,
        pauseOnHover: false,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                slidesToShow: 2,
                slidesToScroll: 2,
                initialSlide: 2
            }
            },
            {
                breakpoint: 480,
                settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
            }
        ]
    };

    return (
        <>
            {
            language === 'en' ? (
                <Helmet>
                <title>Bertolotti Industries</title>
                <meta name="description" content="Specialized in the creation of logistic systems for the steel and aerospace sectors." />    
                </Helmet>
                ) : (
                <Helmet>
                <title>Bertolotti Industries</title>
                <meta name="description" content="Specializzata nella creazione di sistemi logistici per i settori dell'acciaio e dell'alluminio e aerospaziale." />    
                </Helmet>
                )
            }            
            <NavbarComponent />
            <div style={{ height: '100vh', backgroundImage: 'url(/assets/img/bertolottiindustries_header-min.png)', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
                <div style={{ textAlign: 'center' }} className="mx-auto d-flex align-items-center h-100">
                    <div className="container w-100">
                        <h1 className="h1-resp text-white">Bertolotti Industries</h1>
                        <h3 className="h3-resp text-white">
                            {
                                language === 'en' ? `Specialized in the creation of logistic systems for the steel and aerospace sectors`
                                : `Specializzata nella creazione di sistemi logistici per i settori dell'acciaio e dell'alluminio e aerospaziale`
                            }
                        </h3>
                    </div>
                </div>
            </div>
            <div style={{ backgroundColor: '#F3F6F8' }} className="py-3" >
                <h2 className="text-center py-3 mt-3" style={{color: '#262A30', fontSize:'36px'}}>
                    {
                        language === 'en' ? `Trusted by`
                        : `Scelti da`
                    }
                </h2>
                <Slider {...settings} className="py-4" >
                    <img src="/assets/img/leonardo_logo.png" alt="" ></img>
                    <img src="/assets/img/fataepc_logo.png" alt="" ></img>
                    <img src="/assets/img/nmlk_logo.png" alt="" ></img>
                    <img src="/assets/img/smsgroup_logo.png" alt="" ></img>
                    <img src="/assets/img/areva_logo.png" alt="" ></img>
                    <img src="/assets/img/arcelormittal_logo.png" alt="" ></img>
                    <img src="/assets/img/enel_logo.png" alt="" ></img>
                    <img src="/assets/img/marcegaglia_logo.png" alt="" ></img>
                </Slider>
            </div>
            <div className="py-5 mt-5">
                <div className="container my-5">
                    <div className="row g-3 g-lg-5">
                        <div className="col-md-6 d-flex align-items-center">
                            <img src='/assets/img/bertolottiindustries_solutions.png' alt="bertolotti service" className="img-fluid"></img>
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                            <div>
                                <h2 className="py-2" style={{fontSize: '30px'}}>
                                {
                                    language === 'en' ? `Customized solutions for steel and aeronautics sectors`
                                    : `Forniamo soluzioni completamente personalizzate per il settore dell'acciaio, dell'alluminio e areonautico`
                                }
                                </h2>
                                {
                                language === 'en' ? (
                                    <p className="pb-3 pb-md-0">
                                        Bertolotti Industries focuses on delivering bespoke solutions tailored to the specific needs of each customer. The company achieves this by adapting its design and production process to the unique requirements of each project, resulting in fully customized solutions that are designed and manufactured to meet the highest standards of quality and performance. This degree of customization is made possible by a business model with the following features:<br /><br />
                                        <strong>R&D Driven:</strong> with its main asset being the people involved in the product development, i.e. the Group cross R&D team of engineers and specialists who work to improve existing products and develop new ones, not considering the new Automation and Robotics line;<br /><br />
                                        <strong>Fully Integrated:</strong> Bertolotti serves as a single point of contact for clients seeking turnkey logistic solutions, taking care of the entire production process, from design to installation, to after-sales services;<br /><br />
                                        <strong>Flexible:</strong> the production process is highly adaptable and can be tailored to meet specific customer requirements.
                                    </p>
                                ) : (
                                    <p className="pb-3 pb-md-0">
                                        Bertolotti Industries si concentra sulla fornitura di soluzioni su misura per le esigenze specifiche di ogni cliente. L'azienda ottiene questo risultato adattando il proprio processo di progettazione e produttivo ai requisiti unici di ogni progetto, ottenendo soluzioni completamente personalizzate, progettate e realizzate per soddisfare i più elevati standard di qualità e prestazioni. Questo grado di personalizzazione è reso possibile da un modello aziendale con le seguenti caratteristiche:<br /><br />
                                        <strong>R&D Driven:</strong> il suo principale punto di forza sono le persone coinvolte nello sviluppo del prodotto, ovvero il team di ingegneri e specialisti di R&D del Gruppo che lavorano per migliorare i prodotti esistenti e svilupparne di nuovi, senza considerare la nuova linea di automazione e robotica;<br /><br />
                                        <strong>Completamente integrato:</strong> Bertolotti funge da unico punto di contatto per i clienti che cercano soluzioni logistiche chiavi in mano, occupandosi dell'intero processo produttivo, dalla progettazione all'installazione, fino ai servizi post-vendita;<br /><br />
                                        <strong>Flessibilità:</strong> il processo produttivo è altamente adattabile e può essere personalizzato per soddisfare le esigenze specifiche dei clienti.
                                    </p>
                                )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-light py-5">
                <h2 className="text-center m-0">{language === 'en' ? ('Solutions') : ('Soluzioni')}</h2>
            </div>

            <CategorieSelect
                categorie={categorie}
                categoriaSelezionata={categoriaSelezionata}
                onCategoriaClick={filtraPerCategoria}
                lang={language}
            />

            <div className="container">
                <div className="row my-5">
                {/* Render dei prodotti filtrati */}
                {prodottiFiltrati.map(prodotto => (
                    <div key={prodotto.id} className="col-md-4 mb-4">
                    <ProdottoCard 
                        prodotto={prodotto} 
                        isExpanded={espansi.includes(prodotto.id)} 
                        onToggle={toggleEspansione}
                        lang={language}
                    />
                    </div>
                ))}
                </div>
            </div>
            <Footer />
        </>
    )
}
import React, { useState, useEffect } from "react";
import { Container, Image, Navbar, Nav, Form } from "react-bootstrap";
import { RxHamburgerMenu } from 'react-icons/rx'
import { useLanguageContext } from "../Context/LanguageContext";
import { useNavigate } from "react-router-dom";

export default function NavbarComponent() {

    const { language, setLanguage } = useLanguageContext();

    const navigate = useNavigate();

    const pathname = window.location.pathname.split('/')[0] === '' || window.location.pathname.split('/')[0] === 'investor-relations' ? "bertolotti" : window.location.pathname.split('/')[0]
    
    const [isScrolled, setIsScrolled] = useState(false);

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 50);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);
    
    const handleChangeLanguage = (event) => {
        // navigate(`/${event.target.value}`)
        setLanguage(event.target.value)
    }

    const handleChangePage = (page) => {
        navigate(`${page}`)
    }

    return (
        <>
            <Navbar id="navbarBertolotti" expand="lg" className={`py-4 fixed-top ${isScrolled ? 'navbar-scrolled' : ''} ${isMobile ? 'navbar-mobile-black' : ''}`} style={{ background: isScrolled || isMobile ? 'rgba(38, 42, 48)' : 'rgba(38, 42, 48, 0.45)', backdropFilter: isScrolled || isMobile ? 'none' : 'blur(6px)' }}>
                <Container className="d-flex justify-content-lg-center justify-content-between w-100">
                    <Navbar.Brand href="/"><Image style={{ maxWidth: '188px', height: '22px' }} className="img-fluid me-5 px-0" src={`/assets/img/${pathname.split('/')[0]}-logo.svg`} /></Navbar.Brand>
                    <Navbar.Toggle style={{ border: 'none' }} aria-controls="basic-navbar-nav"><RxHamburgerMenu color="white" size={'30'} /></Navbar.Toggle>
                    <Navbar.Collapse id="basic-navbar-nav" className="flex">
                        <Nav id="navbar">
                            <Nav.Link onClick={handleChangePage.bind(this, '/bertolotti-industries/')} style={{ fontSize: '16px' }} className="me-4 px-0">Industries</Nav.Link>
                            <Nav.Link onClick={handleChangePage.bind(this, '/bertolotti-rail/')} style={{ fontSize: '16px' }} className="me-4 px-0">Rail</Nav.Link>
                            <Nav.Link onClick={handleChangePage.bind(this, '/bertolotti-aiworks/')} style={{ fontSize: '16px' }} className="px-0">AI Works</Nav.Link>
                        </Nav>
                        <div className="ms-auto d-lg-flex flex-row align-items-center text-white mt-2 mt-md-0">
                            <a href="/investor-relations/" className="btn btn-outline-white me-2 px-3" style={{ fontSize: '15px', width: '100%' }}>Investor Relations</a>
                            <div className="d-flex mt-3 mt-md-0">
                                <Form.Select onChange={handleChangeLanguage} defaultValue={language} className="bg-secondary text-white me-2" style={{ width: '80px' }}>
                                    <option value="it">IT</option>
                                    <option value="en">EN</option>
                                </Form.Select>
                                <a href="#contacts" className="btn btn-danger">Contacts</a>
                            </div>
                        </div>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}

import Footer from "../components/Footer";
import NavbarComponent from "../components/NavbarComponent";
import { PiPlayCircle } from 'react-icons/pi'
import { PiMapPinLine } from 'react-icons/pi'
import { PiEnvelope } from 'react-icons/pi'
import { PiPhone } from 'react-icons/pi'
import { useLanguageContext } from "../Context/LanguageContext";
import { useNavigate } from "react-router-dom";
import HeaderSlider from "../components/HeaderSlider";
import Milestones from "../view/Home/Milestones";
import Horizon from "../view/Home/Horizon";
import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { PiXBold } from "react-icons/pi";
import { Helmet } from 'react-helmet';

import ReactGA from "react-ga4";

export default function Home() {

    const { language, cookieConsent } = useLanguageContext(); 

    const navigate = useNavigate();

    const handleChangePage = (page) => {
        navigate(`${page}`)
    }

    useEffect(() => {
        if(cookieConsent === 'false' || !cookieConsent) return
        ReactGA.send({ hitType: "pageview", page: window.location.pathname });
    }, [cookieConsent]);

    // Add a state variable to control modal visibility
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Function to open the modal
    const handleOpenModal = () => setIsModalOpen(true);

    // Function to close the modal
    const handleCloseModal = () => setIsModalOpen(false);

    return (
        <>
            {
            language === 'en' ? (
                <Helmet>
                <title>Bertolotti - Our nest: Italy. Our horizon: the world</title>
                <meta name="description" content="Bertolotti provides turnkey solutions and systems of material handling mainly for steel, railway and aircraft industries which operates through the following business units: Bertolotti Industries, Bertolotti Rail, Bertolotti AI Works." />    
                </Helmet>
                ) : (
                <Helmet>
                <title>Bertolotti - Il nostro nido: l'Italia. Il nostro orizzonte: il mondo</title>
                <meta name="description" content="Il Gruppo Bertolotti è specializzato nella realizzazione di sistemi complessi di logistica e manutenzione nei settori siderurgico, aeronautico e ferroviario che opera attraverso le seguenti business unit: Bertolotti Industries, Bertolotti Rail, Bertolotti AI Works." />    
                </Helmet>
                )
            }            
            <NavbarComponent />
            <div className="d-none d-xl-block" style={{ height: '100vh' }}>
                <HeaderSlider />
            </div>
            <div className="container-fluid d-block d-xxl-none" style={{height: '100vh', backgroundImage:'url(/assets/img/bertolottiindustries_header-min.png)', backgroundPosition:'top', backgroundRepeat:'no-repeat', backgroundSize:'cover'}}>
                <div style={{textAlign: 'center'}} className="mx-auto d-flex align-items-center h-100">
                    <div className="container w-100">
                        <h1 className="h1-resp text-white">Bertolotti</h1>
                        <h3 className="h3-resp text-white">Il Gruppo Bertolotti è specializzato nella realizzazione di sistemi complessi di logistica e manutenzione nei settori siderurgico, aeronautico e ferroviario</h3>
                        <div>
                            <a href="#contacts" className="btn btn-danger">
                                {
                                    language === 'en' ? `Contacts`
                                    : `Contattaci`
                                }
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row gx-0">  
                <div className="col-md-6 d-flex align-items-center" style={{background:'#999999', height:'200px'}}>
                    <div className="container">
                        <div className="text-center w-100">
                            <h2 className="text-white pt-3 pb-2">
                                {
                                    language === 'en' ? `VISION`
                                    : `VISION`
                                }
                            </h2>
                            {
                                language === 'en' ? (
                                    <p style={{color:'#F3F6F8', padding: '0 30px'}}>We develop systems for logistics, predictive maintenance and automation with the aims of simplifying complex problems with end-to-end solutions</p>
                                ) : (
                                    <p style={{color:'#F3F6F8', padding: '0 30px'}}>Sviluppiamo sistemi per la logistica, la manutenzione predittiva e l'automazione con l'obiettivo di semplificare problemi complessi con soluzioni end-to-end.</p>
                                )
                            }

                        </div>
                    </div>
                </div>
                <div className="col-md-6 d-flex align-items-center" style={{background:'#333333', height:'200px'}}>
                    <div className="container">
                        <div className="text-center w-100">
                            <h2 className="text-white pt-3 pb-2">
                                {
                                    language === 'en' ? `MISSION`
                                    : `MISSION`
                                }
                            </h2>
                                {
                                    language === 'en' ? (
                                        <p style={{color:'#F3F6F8', padding: '0 30px'}}>Improving efficiency, safety and sustainability for mission critical systems and infrastructures</p>
                                    ) : (
                                        <p style={{color:'#F3F6F8', padding: '0 30px'}}>Migliorare l' efficienza, la sicurezza e la sostenibilità per sistemi e infrastrutture mission critical</p>
                                    )
                                }
                        </div>
                    </div>
                </div>
            </div>
            <div className="container py-5">
                <div>
                    <h2 className="mb-1">
                        {
                            language === 'en' ? `Solutions`
                            : `Solutions`
                        }
                    </h2>
                    <p className="mb-4 pb-3 pb-md-0" dangerouslySetInnerHTML={{ __html: language === 'en' ? `Bertolotti provides turnkey solutions and systems of material handling mainly for steel, railway and aircraft industries.`
                            : `Il <strong>Gruppo Bertolotti</strong> è specializzato nella realizzazione di sistemi complessi di logistica e manutenzione nei settori siderurgico, aeronautico e ferroviario che opera attraverso le seguenti business unit:` }} />
                </div>
                <div className="row gx-3 g-3">
                    <div className="col-md-4">
                        <div className="d-flex p-4" style={{backgroundColor:'#000', height:'540px', borderRadius:'10px', backgroundImage:'url(/assets/img/card_industries_bg.png)'}}> 
                            <div className="mt-auto">
                                <h3 style={{fontSize:'42px', fontWeight:'800', color:'white'}} className="py-1">
                                {
                                    language === 'en' ? `Industries`
                                    : `Industries`
                                }
                                </h3>
                                <button onClick={handleChangePage.bind(this, 'bertolotti-industries')} className="btn btn-danger">
                                {
                                language === 'en' ? `Find out more`
                                : `Scopri di più`
                                }
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="d-flex p-4" style={{backgroundColor:'#000', height:'540px', borderRadius:'10px', backgroundImage:'url(/assets/img/card_rail_bg.png)'}}>
                            <div className="mt-auto">
                                <h3 style={{fontSize:'42px', fontWeight:'800', color:'white'}} className="py-1">Rail</h3>
                                <button onClick={handleChangePage.bind(this, 'bertolotti-rail')} className="btn btn-danger">
                                {
                                    language === 'en' ? `Find out more`
                                    : `Scopri di più`
                                }
                                    </button>
                            </div>                            
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="d-flex p-4" style={{backgroundColor:'#000', height:'540px', borderRadius:'10px', backgroundImage:'url(/assets/img/card_aiworks_bg.png)'}}> 
                            <div className="mt-auto">
                                <h3 style={{fontSize:'42px', fontWeight:'800', color:'white'}} className="py-1">AI Works</h3>
                                <button onClick={handleChangePage.bind(this, 'bertolotti-aiworks')} className="btn btn-danger">
                                {
                                    language === 'en' ? `Find out more`
                                    : `Scopri di più`
                                }
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{backgroundColor:'#F3F6F8'}} className="py-5 mt-5">
                <div className="container my-5">
                    <div className="row g-lg-5 g-3">
                        <div className="col-md-6">
                            <img src='/assets/img/bertolotti_service.png' alt="bertolotti service" className="img-fluid"></img>
                        </div>
                        <div className="col-md-6 d-flex align-items-center">
                            <div>
                                <h2 className="py-2">Service</h2>
                                <p className="pb-3 pb-md-0">
                                    {
                                        language === 'en' ? `Bertolotti SpA Service technical specialists are in continuous contact with our customers to conduct remote monitoring, troubleshooting and onsite interventions. The implementation of our tailormade computerized maintenance management system minimizes the unplanned stoppages with a direct positive impact on equipment availability and time utilization factor.`
                                        : `I tecnici specializzati di Bertolotti Spa Service sono in continuo contatto con i nostri clienti per effettuare il monitoraggio remoto, la risoluzione dei problemi e gli interventi in loco. L'implementazione del nostro sistema di gestione della manutenzione computerizzata su misura riduce al minimo le interruzioni non programmate, con un impatto positivo diretto sulla disponibilità delle apparecchiature e sul fattore di utilizzo del tempo.`
                                    }
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                <div className="container my-5 py-5">
                    <Milestones />
                </div>
                <Horizon />

                {/*<div style={{backgroundColor:'#C01725'}}>
                    <div className="container">
                        <div className="row py-5 gy-4 gx-0">
                            <div className="col-md-3 text-center">
                                <div className="text-white" style={{fontSize:'96px', fontWeight:'800'}}>64 M</div>
                                <p className="text-white" style={{fontSize:'24px'}}>Backlog</p>
                            </div>
                            <div className="col-md-3 text-center">
                                <div className="text-white" style={{fontSize:'96px', fontWeight:'800'}}>13 M</div>
                                <p className="text-white" style={{fontSize:'24px'}}>Revenue 2024</p>
                            </div>
                            <div className="col-md-3 text-center">
                                <div className="text-white" style={{fontSize:'96px', fontWeight:'800'}}>60</div>
                                <p className="text-white" style={{fontSize:'24px'}}>
                                {
                                    language === 'en' ? `Customers`
                                    : `Clienti`
                                }                                    
                                </p>
                            </div>
                            <div className="col-md-3 text-center">
                                <div className="text-white" style={{fontSize:'96px', fontWeight:'800'}}>26</div>
                                <p className="text-white" style={{fontSize:'24px'}}>
                                {
                                    language === 'en' ? `Installations`
                                    : `Installazioni`
                                }                                    
                                </p>
                            </div>
                        </div>
                    </div>
                </div>*/}

                <div style={{backgroundImage:'url(/assets/img/istituzionale_gruppo.png)', backgroundPosition:'center', backgroundRepeat:'no-repeat', height:'86vh'}} >
                    <div className="mx-auto d-flex align-items-center h-100">
                        <div className="w-100 text-center">
                            <PiPlayCircle className="cursor-pointer" style={{ cursor: 'pointer' }} onClick={handleOpenModal}  color="#fff" size={72} />
                        </div> 
                    </div>
                </div>

                <Modal 
                    isOpen={isModalOpen} 
                    onRequestClose={handleCloseModal} 
                    contentLabel="Video Modal" 
                    style={{
                        content: {
                          top: '50%',
                          left: '50%',
                          right: 'auto',
                          bottom: 'auto',
                          marginRight: '-50%',
                          transform: 'translate(-50%, -50%)',
                          width: '90%', // Buon punto di partenza per dispositivi mobili
                          maxWidth: '500px', // Assicura che il modal non diventi troppo largo su schermi grandi
                          border: '1px solid #ccc',
                          borderRadius: '10px',
                        },
                      }}                                          
                >
                    <div className="text-end mb-2">
                        <button className="btn btn-link" onClick={handleCloseModal}><PiXBold /></button>
                    </div>
                    <video width="100%" controls autoPlay>
                        <source src="/assets/video/gruppo_istituzionale_novoice.mp4" type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                </Modal>

                <div className="py-5 my-5">
                    <div className="container text-center">
                        <h2>
                        {
                            language === 'en' ? 'Contacts'
                            : "Contatti"
                        }
                        </h2>
                        <div className="row my-5 g-3">
                            <div className="col-md-4" >
                                <div className="py-4" style={{backgroundColor:'#F3F6F8', borderRadius:'10px'}}>    
                                    < PiMapPinLine color="#C01725" size={30} />
                                    <p className="mt-3" style={{fontSize:'24px', fontWeight:'600'}}>
                                    {
                                        language === 'en' ? `Location`
                                        : `Posizione`
                                    }
                                    </p>
                                    <p className="mb-0">Località Sant'Antonio<br />50063, Figline e Incisa Valdarno (FI)</p>
                                </div>
                            </div>
                            <div className="col-md-4" >
                                <div className="py-4" style={{backgroundColor:'#F3F6F8', borderRadius:'10px'}}>  
                                    < PiEnvelope color="#C01725" size={30} />
                                    <p className="mt-3" style={{fontSize:'24px', fontWeight:'600'}} >E-mail </p>
                                    <p className="mb-0">
                                    {
                                        language === 'en' ? `Get support via mail:`
                                        : `Contattaci tramite mail a:`
                                    }
                                    <br />welcome@bertolottispa.it</p>
                                </div>
                            </div>
                            <div className="col-md-4" >
                                <div className="py-4" style={{backgroundColor:'#F3F6F8', borderRadius:'10px'}}>
                                    < PiPhone color="#C01725" size={30} />
                                    <p className="mt-3" style={{fontSize:'24px', fontWeight:'600'}}>
                                    {
                                        language === 'en' ? `Phone`
                                        : `Telefono`
                                    }
                                    </p>
                                    <p className="mb-0">
                                    {
                                        language === 'en' ? `Contact us by phone:`
                                        : `Chiamaci al:`
                                    }    
                                    <br />+39 055 833171
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            <Footer />
        </>
    )
}
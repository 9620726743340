import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { PiLinkedinLogoFill } from 'react-icons/pi'
import { PiMapPinLine } from 'react-icons/pi'
import { PiEnvelope } from 'react-icons/pi'
import { PiPhone } from 'react-icons/pi'
import { useLanguageContext } from "../Context/LanguageContext";

export default function Footer() {

    const { language } = useLanguageContext()
    const codice_etico_file = '/documents/codice_etico.pdf'
    const politica_anticorruzione_file = '/documents/politica_anticorruzione.pdf'
    const politica_responsabilita_sociale = '/documents/politica_SA8000.pdf'
    const internal_dealing_file = '/documents/internal_dealing.pdf'
    const gestione_interna_file = '/documents/gestione_interna.pdf'
    const privacy_file = '/documents/privacy.pdf'
    const cookies_file = '/documents/cookies.pdf'

    const bv_iso3834 = '/documents/bv_certification_ISO3834.png'
    const bv_9001 = '/documents/bv_certification_9001.png'
    const bv_14001 = '/documents/bv_certification_14001.png'
    const bv_37001 = '/documents/bv_certification_37001.png'
    const emas = '/documents/emas.png'
    const bv_iso3834_file = 'documents/ISO3834_2_rev0.pdf'
    const bv_9001_file = '/documents/uni_en_iso_9001_1.pdf'
    const bv_14001_file = '/documents/uni_en_iso_14001_1.pdf'
    const bv_37001_file = '/documents/uniiso_37001.pdf'
    const emas_file = '/documents/dichiarazione_ambientale_rev_6_0.pdf'

    return (
        <>
            <div className="py-5" style={{ backgroundColor: '#262A30' }}>
                <div className="container">
                    <Row className="gx-3 gx-lg-5">
                        <Col md='2'>
                            <div>
                                <Image onClick={() => { window.location.href = '/' }} style={{ cursor: 'pointer' }}  src="/assets/img/logo_footer.png" className="img-fluid" />
                            </div>
                        </Col>
                        <Col md='4'className="pt-5 pt-md-0" >
                            <div className="mb-3">
                                <div className="d-flex flex-row">
                                    <div className="ps-2">
                                        <label className="text-white"><strong>Legal</strong></label>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2">
                                <div className="d-flex flex-row">
                                    <div className="ps-2">
                                        <a href={codice_etico_file} className="text-white footerLink" target="_blank" rel="noopener noreferrer">Codice Etico</a>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2">
                                <div className="d-flex flex-row">
                                    <div className="ps-2">
                                        <a href={politica_anticorruzione_file} className="text-white footerLink" target="_blank" rel="noopener noreferrer">Politica per la qualità, sicurezza, ambiente e anticorruzione</a>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2">
                                <div className="d-flex flex-row">
                                    <div className="ps-2">
                                        <a href={politica_responsabilita_sociale} className="text-white footerLink" target="_blank" rel="noopener noreferrer">Politica per la responsabilità sociale</a>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2">
                                <div className="d-flex flex-row">
                                    <div className="ps-2">
                                        <a href={internal_dealing_file} className="text-white footerLink" target="_blank" rel="noopener noreferrer">Internal Dealing</a>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2">
                                <div className="d-flex flex-row">
                                    <div className="ps-2">
                                        <a href={gestione_interna_file} className="text-white footerLink" target="_blank" rel="noopener noreferrer">Gestione Interna e Comunicazione Esterna delle Informazioni Privilegiate</a>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2">
                                <div className="d-flex flex-row">
                                    <div className="ps-2">
                                        <a href="https://bertolottispa.whistleblowingweb.it" className="text-white footerLink" target="_blank" rel="noopener noreferrer">Segnalazioni / Whistleblowing</a>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2">
                                <div className="d-flex flex-row">
                                    <div className="ps-2">
                                        <a href="/segnalazioni-sa8000/" className="text-white footerLink" rel="noopener noreferrer">Segnalazioni SA8000</a>
                                    </div>
                                </div>
                            </div>
                            {/*<div className="mb-2">
                                <div className="d-flex flex-row">
                                    <div className="ps-2">
                                        <a href="https://bertolottispa.whistleblowingweb.it" className="text-white footerLink" target="_blank" rel="noopener noreferrer">Segnalazioni SA 8000</a>
                                    </div>
                                </div>
                            </div>*/}
                            <div className="mb-2">
                                <div className="d-flex flex-row">
                                    <div className="ps-2">
                                        <a href={privacy_file} className="text-white footerLink" target="_blank" rel="noopener noreferrer">Privacy Policy</a> -  <a href={cookies_file} className="text-white footerLink" target="_blank" rel="noopener noreferrer">Cookie Policy</a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md='3'className="pt-5 pt-md-0" >
                            <div className="mb-3">
                                <div className="d-flex flex-row">
                                    <div className="ps-2">
                                        <label className="text-white"><strong>Certificazioni</strong></label>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2">
                                <div className="d-flex flex-row">
                                    <div className="ps-2">
                                        <a href="/certificazioni-bertolotti-spa/" className="text-white footerLink">Bertolotti S.p.a.</a>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2">
                                <div className="d-flex flex-row">
                                    <div className="ps-2">
                                        <a href="/certificazioni-bertolotti-rail/" className="text-white footerLink">Bertolotti Rail S.r.l.</a>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2">
                                <div className="d-flex flex-row">
                                    <div className="ps-2">
                                        <a href="/certificazioni-bertolotti-aiworks/" className="text-white footerLink">Bertolotti AI Works S.r.l.</a>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col md='3'className="pt-5 pt-md-0 d-flex flex-column justify-content-between" id="contacts" >
                            <div className="mb-3">
                                <div className="d-flex flex-row">
                                    <div className="ps-2">
                                        <label className="text-white"><strong>{language === 'it' ? 'Contatti' : 'Contacts' }</strong></label>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2">
                                <div className="d-flex flex-row align-items-center">
                                    <div className="ps-2">
                                        < PiMapPinLine color="#fff" size={16} />
                                        <a href="https://maps.app.goo.gl/6eCkU62SyL9Sx2Ca8" className="text-white footerLink ps-2">Località Sant'Antonio 50063, Figline e Incisa Valdarno (FI)</a>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2">
                                <div className="d-flex flex-row">
                                    <div className="ps-2">
                                        < PiEnvelope color="#fff" size={16} />
                                        <a href="mailto:welcome@bertolottispa.it" className="text-white footerLink ps-2">welcome@bertolottispa.it</a>
                                    </div>
                                </div>
                            </div>
                            <div className="mb-2">
                                <div className="d-flex flex-row">
                                    <div className="ps-2">
                                        < PiPhone color="#fff" size={16} />
                                        <a href="tel:+39055833171" className="text-white ps-2 footerLink">+39 055 833171</a>
                                    </div>
                                </div>
                            </div>
                            <div className="mt-auto">&nbsp;</div>
                        </Col>
                    </Row>
                    <div style={{ borderTop: '1px solid rgba(255, 255, 255, 0.30)' }} className="mt-5 d-flex flex-column flex-md-row pt-4 pt-md-4 py-1 py-md-1">
                        <PiLinkedinLogoFill size={'30'} color="white"/> 
                        <label className="text-white d-flex align-items-center py-2 py-md-0 ms-md-2">© Copyright 2024. All Rights Reserved.</label>
                        <label className="text-white d-flex align-items-center ms-md-auto">Designed and developed by <a href="https://www.metriks.ai/" className="footerLink ms-1 d-flex align-items-center">METRIKS.AI</a></label>
                    </div>
                </div>
            </div>
        </>
    )
}
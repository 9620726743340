import NavbarComponent from '../components/NavbarComponent.jsx';
import Footer from '../components/Footer.jsx';
import { Row, Col, Container } from 'react-bootstrap';
import { useEffect, useState } from 'react';
import Helmet from 'react-helmet'
import { useLanguageContext } from '../Context/LanguageContext.jsx';

function CertificazioniRail() {
  const { language, cookieConsent } = useLanguageContext();

  return (
    <>
      <Helmet>
        <title>Certificazioni - Bertolotti S.p.A.</title>
      </Helmet>
      <NavbarComponent />
      <Container className='mt-5 pt-5'>
      <div style={{ height: '30vh', backgroundImage: 'url(/assets/img/bertolottirail_header.png)', backgroundPosition: 'center', backgroundRepeat: 'no-repeat' }}>
          <div style={{ textAlign: 'center' }} className="mx-auto d-flex align-items-center h-100">
              <div className="container w-100">
                  <h1 className="h1-resp text-white">
                  {
                      language === 'en' ? `Certifications - Bertolotti Rail S.r.l.`
                      : `Certificazioni - Bertolotti Rail S.r.l.`
                  }
                  </h1>
              </div>
          </div>
      </div>
      <div className='mt-5 d-flex justify-content-center'>
        <div className='w-50'>
          <ul className='list-unstyled'>
            <li className='border-bottom py-2'><h3><a href='/documents/rail/UNI_EN_ISO_9001.pdf' target='_blank' style={{ textDecoration: "none", color: "#000000" }}>ISO 9001</a></h3></li>
            <li className='border-bottom py-2'><h3><a href='/documents/rail/UNI_EN_ISO_14001.pdf' target='_blank' style={{ textDecoration: "none", color: "#000000" }}>ISO 14001</a></h3></li>
            <li className='border-bottom py-2'><h3><a href='/documents/rail/UNI_ISO_37001.pdf' target='_blank' style={{ textDecoration: "none", color: "#000000" }}>ISO 37001</a></h3></li>
            <li className='border-bottom py-2'><h3><a href='/documents/rail/UNI_ISO_45001.pdf' target='_blank' style={{ textDecoration: "none", color: "#000000" }}>ISO 45001</a></h3></li>
          </ul>
        </div>
      </div>
      </Container>
      <Footer />  
    </>
  );
}

export default CertificazioniRail;
